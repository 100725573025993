export const initLocation = async () => {
  try {
    const {
      coords: { longitude, latitude },
    } = await new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(resolve, reject, {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 27000,
        });
      } else {
        reject(null);
        console.error("Geo not available");
      }
    });

    if( localStorage.getItem("useStorage") === "1"){
      localStorage.setItem("location", `${longitude},${latitude}`);
    }

    return location;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getLocation = async () => {
  try {
    return localStorage.getItem("location");
  } catch (error) {
    console.error(error);

    return null;
  }
};
