import * as React from "react";
import axios from "axios";
import { DateTime } from "luxon";
import { deepCopy, selectMenuStyle } from "libs";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import {
  PAGE_VIEW_LIST,
  PAGE_VIEW_ITEMS,
  STEP_VIEW_LIST,
  STEP_VIEW_ITEMS,
} from "../../../constants";
import location from "assets/img/location.png";
import logoTufessa from "assets/img/logos/logo-tufesa.png";
import {
  DestinationOptionType,
  DestinationType,
  OriginOptionType,
  OriginType,
} from "types";
import TicketCategoryModal from "components/Tufesa/Steps/TicketCategoryModal";
import ContactModal from "components/Tufesa/Steps/ContactModal";
import { useModal } from "components/UI/Modal/ModalProvider";
import { defaultTufesaState } from "AppProvider";

import AppContext from "AppContext";
import "react-datepicker/dist/react-datepicker.css";

export interface OriginDestinationDatePropsType {}

const { ValueContainer } = components;
const IconOption = ({ children, ...props }: any) => (
  <ValueContainer className="border border-gray-200" {...props}>
    <div className="flex w-full p-2 items-center">
      <img
        src={location}
        style={{ width: 25, height: 25 }}
        alt={props.value?.label}
      />
      {children}
    </div>
  </ValueContainer>
);

const OriginDestinationDate: React.FC<OriginDestinationDatePropsType> = () => {
  const context = React.useContext(AppContext);
  const { tufesaState, mainState, setAppState } = context;
  const { session } = mainState;
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    phone,
    email,
    isDirtyData,
  } = tufesaState;

  const [roundTravel, setRoundTravel] = React.useState<boolean>(!!returnDate);
  const [origins, setOrigins] = React.useState<OriginOptionType[]>([]);
  const [destinations, setDestinations] = React.useState<
    DestinationOptionType[]
  >([]);
  const [destinationsEnabled, setDestinationsEnabled] =
    React.useState<boolean>(false);
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
  const ticketCategoryModal = useModal((props) => (
    <TicketCategoryModal {...props} />
  ));
  const contactModal = useModal((props) => <ContactModal {...props} />);
  const [apiUrl, setApiUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (!origin) {
      setIsRequesting(true);
      if (!apiUrl) {
        const api_url = session.params.find(
          (param) => param.key === "tufesa_api_url"
        ).value;
        setApiUrl(api_url);
      } else {
        axios
          .get(`${apiUrl}/origins`)
          .then(({ data }: any) => {
            let options = data?._Response?.dataField[0]?._point || [];

            options = options.map((option: OriginType) => {
              return {
                value: option.idField,
                label: option.descriptionField,
              };
            });

            setOrigins(options);
            setIsRequesting(false);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [origin, setOrigins, setIsRequesting, session, apiUrl]);

  const onOriginChange = ({ value }: OriginOptionType) => {
    setIsRequesting(true);
    axios
      .get(`${apiUrl}/destinations?from=${value}`)
      .then(({ data }: any) => {
        let options = data?._Response?.dataField[0]?._point || [];

        options = options.map((option: DestinationType) => {
          return {
            value: option.idField,
            label: option.descriptionField,
          };
        });

        const orgn = origins.find((o) => o.value === value);

        setDestinations(options);
        setDestinationsEnabled(true);
        setIsRequesting(false);
        setAppState({
          ...context,
          tufesaState: {
            ...tufesaState,
            origin: {
              idField: orgn.value,
              descriptionField: orgn.label,
            },
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const onDestinationChange = ({ value }: DestinationOptionType) => {
    const dstn = destinations.find((o) => o.value === value);

    setAppState({
      ...context,
      tufesaState: {
        ...tufesaState,
        destination: {
          idField: dstn.value,
          descriptionField: dstn.label,
        },
      },
    });
  };

  const onContinueClickHandler = async () => {
    if (isDirtyData) {
      setAppState({
        ...context,
        tufesaState: {
          ...tufesaState,
          pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.FECHA],
          stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.HORARIOS],
          departureSchedule: null,
          returnSchedule: null,
          choosingDepartureSeats: false,
          choosingReturnSeats: false,
          isDirtyData: false,
        },
      });
    } else {
      const ticketCategoryModalResult = await ticketCategoryModal();
      let newTufesaState = {
        ...tufesaState,
      };

      if (ticketCategoryModalResult?.message === "success") {
        newTufesaState = {
          ...newTufesaState,
          tickets: ticketCategoryModalResult?.data,
        };
        setAppState({
          ...context,
          tufesaState: {
            ...newTufesaState,
          },
        });

        const contactModalResult = await contactModal();
        if (contactModalResult?.message === "success") {
          setAppState({
            ...context,
            tufesaState: {
              ...newTufesaState,
              ...contactModalResult.data,
              pageView: PAGE_VIEW_LIST[1],
              stepView: STEP_VIEW_LIST[0],
            },
          });
        } else {
          setAppState({
            ...context,
            tufesaState: {
              ...tufesaState,
              marketing: false,
              email: null,
              phone: null,
            },
          });
        }
      }
    }
  };

  const onUncheckHandler = (checked: boolean) => {
    if (!checked) {
      setAppState({
        ...context,
        tufesaState: {
          ...tufesaState,
          returnDate: null,
        },
      });
    }

    setRoundTravel(checked);
  };

  const resetClickHandler = () => {
    setAppState({
      ...context,
      tufesaState: deepCopy(defaultTufesaState),
    });

    setDestinationsEnabled(true);
  };

  const canContinue =
    origin &&
    destination &&
    departureDate &&
    ((roundTravel && returnDate) || (!roundTravel && !returnDate));

  return (
    <div className="flex flex-col w-5/6 items-center mx-auto">
      <div className="flex w-full flex-col">
        <img
          src={logoTufessa}
          alt="tufessa logo"
          className="w-44 mx-auto my-8"
        />
        <span
          onClick={resetClickHandler}
          className="text-tufesa-main text-base cursor-pointer ml-auto"
        >
          Reiniciar
        </span>
        <Select
          placeholder="Selecciona origen"
          className="mb-2 text-tufesa-main"
          isDisabled={isRequesting || isDirtyData}
          onChange={onOriginChange}
          options={origins}
          components={{ Control: IconOption }}
          value={
            origin
              ? { value: origin.idField, label: origin.descriptionField }
              : null
          }
          styles={{
            control: (styles) => ({ ...styles, padding: "0.7rem" }),
            singleValue: (styles, _) => ({
              ...styles,
              color: isDirtyData ? "gray" : "#fe5a1f",
            }),
            menuList: selectMenuStyle,
          }}
        />
        <Select
          placeholder="Selecciona destino"
          className="mb-2 text-tufesa-main"
          isDisabled={!destinationsEnabled || isRequesting || isDirtyData}
          onChange={onDestinationChange}
          options={destinations}
          components={{ Control: IconOption }}
          value={
            destination
              ? {
                  value: destination.idField,
                  label: destination.descriptionField,
                }
              : null
          }
          styles={{
            control: (styles) => ({ ...styles, padding: "0.7rem" }),
            singleValue: (styles, _) => ({
              ...styles,
              color: isDirtyData ? "gray" : "#fe5a1f",
            }),
            menuList: selectMenuStyle,
          }}
        />

        <div>
          <label className="flex items-center space-x-3 mt-2">
            <input
              type="checkbox"
              name="marketing"
              defaultChecked={roundTravel}
              className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-tufesa-main checked:border-tufesa-dark focus:outline-none"
              onClick={() => onUncheckHandler(!roundTravel)}
            />
            <span className="text-gray-500 font-medium">Viaje Redondo</span>
          </label>
        </div>

        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/2 pr-2">
            <label
              htmlFor="departureDate"
              className="text-sm text-gray-500 mb-1 mt-2"
            >
              Salida
            </label>
            <DatePicker
              id="departureDate"
              placeholderText="Selecciona fecha de salida"
              className="border border-gray-200 p-2 text-tufesa-main rounded-sm mb-8 w-full"
              selected={DateTime.fromISO(departureDate).toJSDate()}
              onChange={(date) =>
                setAppState({
                  ...context,
                  tufesaState: {
                    ...tufesaState,
                    departureDate: DateTime.fromJSDate(
                      date as Date
                    ).toISODate(),
                  },
                })
              }
            />
          </div>

          {roundTravel && (
            <div className="flex flex-col w-1/2 pl-2">
              <label
                htmlFor="arrivalDate"
                className="text-sm text-gray-500 mb-1 mt-2"
              >
                Regreso
              </label>
              <DatePicker
                id="datepicker2"
                placeholderText="Selecciona fecha de regreso"
                className="border border-gray-200 p-2 text-tufesa-main rounded-sm mb-8 w-full"
                selected={
                  returnDate ? DateTime.fromISO(returnDate).toJSDate() : null
                }
                onChange={(date) =>
                  setAppState({
                    ...context,
                    tufesaState: {
                      ...tufesaState,
                      returnDate: DateTime.fromJSDate(date as Date).toISODate(),
                    },
                  })
                }
              />
            </div>
          )}
        </div>

        <button
          className={`uppercase w-full text-white p-4 text-center rounded-sm ${
            canContinue
              ? "cursor-pointer bg-tufesa-dark hover:bg-tufesa-main"
              : "cursor-not-allowed bg-gray-400"
          }`}
          onClick={canContinue ? onContinueClickHandler : null}
        >
          {phone || email ? "aceptar" : "continuar"}
        </button>
      </div>
    </div>
  );
};

export default OriginDestinationDate;
