import * as React from 'react';

import AppContext from 'AppContext';
import { PAYMENT_TYPE, SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import scanea from 'assets/img/icon-scan.png';
import cardIcon from 'assets/img/cardIcon.png';
import { DEVICE_PAYMENT_METHOD } from 'types';

interface PaymentTypeProps {

}


const PaymentType: React.FC<PaymentTypeProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const { session: { device } } = mainState;
  const paymentMethods = device?.settings?.paymentMethod;


  React.useEffect(() => {
    if (paymentMethods.length === 1) {
      let paymentType = PAYMENT_TYPE.CASH;
      let currentComponent = SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES

      if (paymentMethods.includes(DEVICE_PAYMENT_METHOD.CARD)) {
        paymentType = PAYMENT_TYPE.CARD;
      } else if (paymentMethods.includes(DEVICE_PAYMENT_METHOD.TOKEN)) {
        paymentType = PAYMENT_TYPE.TOKEN;
        currentComponent = SYSTEMATIC_ROUTER_ENUM.SCAN;
      }

      setAppState({
        ...context,
        mainState: {
          ...mainState,
          currentComponent,
          paymentType,
        },
      });
    }
  }, [paymentMethods]);


  const onPaymentHandler = (withToken: boolean = false) => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: withToken ? SYSTEMATIC_ROUTER_ENUM.SCAN : SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES,
        paymentType: withToken ? PAYMENT_TYPE.TOKEN : PAYMENT_TYPE.CARD
      }
    });
  }

  const card = () => (
    <div className="w-1/2 flex flex-col mx-auto text-center p-6">
      <img
        className="w-52 h-44 mx-auto text-center"
        src={scanea}
        alt="Token"
        onClick={() => onPaymentHandler()}
      />
      <span className="capitalize">Token PagoCheck</span>
    </div>
  );

  const token = () => (
    <div className="w-1/2 flex flex-col mx-auto text-center p-6">
      <img
        className="w-52 h-44 mx-auto text-center"
        src={cardIcon}
        alt="Tarjeta"
        onClick={() => onPaymentHandler(true)}
      />
      <span className="capitalize">Pago con tarjeta</span>
    </div>
  );

  const both = () => (
    <>
    {card()}
    {token()}
    </>
  );

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col text-center">
        <h1 className="text-8xl font-myriad-bold mb-12 capitalize">Método de pago</h1>
        <div className="flex text-4xl">
          {paymentMethods.includes(DEVICE_PAYMENT_METHOD.CARD) && card()}
          {paymentMethods.includes(DEVICE_PAYMENT_METHOD.TOKEN) && token()}
          {paymentMethods.length === 0 && both()}
        </div>
      </div>
    </div>
  );
}

export default PaymentType;