import * as React from 'react';
import { DateTime } from 'luxon';
import logoTufessa from 'assets/img/logos/logo-tufesa.png'
import AppContext from 'AppContext';
import { PAGE_VIEW_LIST, STEP_VIEW_LIST, PAGE_VIEW_ITEMS } from '../../../constants';
import ScheduleStep from './ScheduleStep';
import OriginDestinationDate from './OriginDestinationDate';
import Seats from './Seats';
import Resume from './Resume';

export interface StepControllerProps {

}

const StepController: React.FC<StepControllerProps> = () => {
  const context = React.useContext(AppContext);
  const { tufesaState, setAppState } = context;
  const {
    pageView,
    origin,
    destination,
    departureDate,
    returnDate,
    stepView,
    departureSchedule,
    returnSchedule,
    choosingDepartureSeats,
    choosingReturnSeats,
    printing
  } = tufesaState;
  const handleEditDate = () => {
    setAppState({
      ...context,
      tufesaState: {
        ...tufesaState,
        pageView: PAGE_VIEW_LIST[0],
        isDirtyData: true
      }
    });
  }

  const handleStepChange = (position: number) => {
    setAppState({
      ...context,
      tufesaState: {
        ...tufesaState,
        departureSchedule: null,
        returnSchedule: null,
        choosingDepartureSeats: false,
        choosingReturnSeats: false,
        departureTicket: null,
        returnTicket: null,
        pageView: PAGE_VIEW_LIST[position === 0 ? 1 : position],
        stepView: STEP_VIEW_LIST[position],
      }
    });
  }

  return (
    <div className="flex flex-col w-full bg-white text-xl">
      {pageView.position !== PAGE_VIEW_ITEMS.ORIGEN_DESTINO && !printing && (
        <div className="flex flex-col px-4 pb-2">
          <img src={logoTufessa} alt="tufessa logo" className="w-44 mx-auto mt-1 mb-2" />
          <div id="currentStep" className="flex flex-row mb-2">
            {STEP_VIEW_LIST.map((step, index) => {
              return (
                <React.Fragment key={step.name}>
                  <div onClick={() => stepView.position > index ? handleStepChange(stepView.position - 1) : null} className="flex w-1/5">
                    <div className={`w-full text-center text-lg font-bold ${stepView.position === index ? 'text-tufesa-main' : 'text-gray-500'} cursor-pointer`}>{`${step.position + 1} / ${step.name}`}</div>
                  </div>
                  {index < (STEP_VIEW_LIST.length - 1) && (
                    <div className="flex w-1/5 items-center justify-center">
                      <svg width="100" height="1"><line x1="10" y1="0" x2="100" y2="0" stroke="black" /></svg>
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
          {pageView.position !== PAGE_VIEW_ITEMS.RESUMEN && (
            <div className="flex flex-col w-full justify-center text-2xl">
              <div className="flex justify-center mb-2">
                <span className="flex items-center text-2xl text-tufesa-light uppercase">{origin?.descriptionField}</span>
                <span className="flex items-center text-4xl text-gray-400 uppercase mx-4 ">
                  {((departureDate && returnDate && !departureSchedule) ||
                    (departureDate && !returnDate) ||
                    choosingDepartureSeats) && `>`}
                  {((returnDate && departureSchedule && !returnSchedule) || choosingReturnSeats) && `<`}

                </span>
                <span className="flex items-center text-2xl text-tufesa-light uppercase">{destination?.descriptionField}</span>
              </div>
              <div className="flex justify-center">
                <div className="text-base">
                  <span className="mr-1">
                    Ida: {DateTime.fromISO(departureDate).toLocaleString({ ...DateTime.DATE_MED, weekday: 'long' })}
                  </span>|
                  {returnDate && (
                    <span className="ml-1">
                      Vuelta: {DateTime.fromISO(returnDate).toLocaleString({ ...DateTime.DATE_MED, weekday: 'long' })} |
                    </span>
                  )}
                  <span onClick={handleEditDate} className="ml-1 text-tufesa-light hover:text-tufesa-main cursor-pointer">Editar</span></div>
              </div>
            </div>
          )}

        </div>
      )}

      <RenderComponent position={pageView.position} />
    </div>
  );
}


const RenderComponent = ({ position }: { position: number }) => {
  let component;

  switch (position) {
    case PAGE_VIEW_ITEMS.ORIGEN_DESTINO:
      component = <OriginDestinationDate />;
      break;
    case PAGE_VIEW_ITEMS.FECHA:
      component = <ScheduleStep />;
      break;
    case PAGE_VIEW_ITEMS.ASIENTOS:
      component = <Seats />;
      break;
    case PAGE_VIEW_ITEMS.RESUMEN:
      component = <Resume />;
      break;
    default:
      component = null;
      break;

  }
  return component;
}

export default StepController;