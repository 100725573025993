import * as React from "react";
import { sha256 } from "crypto-hash";
import RICIBs from "react-individual-character-input-boxes";
import logo from "assets/img/logos/logo-pagocheck.png";
import lockIcon from "assets/img/icons/lock.png";
import unlockIcon from "assets/img/icons/unlock.png";

const AMOUNT = 4;

interface LockScreenProps {
  onLockChange: (locked: boolean) => void;
}

const LockScreen = ({ onLockChange }: LockScreenProps): JSX.Element => {
  const storedPass = localStorage.getItem("lockPassword");
  const [showCancel, setShowCancel] = React.useState(!storedPass);
  const [val, setVal] = React.useState("");
  const [lockCode, setLockCode] = React.useState("");
  const [lockCodeVerify, setLockCodeVerify] = React.useState("");
  const [error, setError] = React.useState("");

  const savePassword = React.useCallback(async () => {
    localStorage.setItem("lockPassword", await sha256(lockCode));
    setShowCancel(false);
    setLockCode("");
    setLockCodeVerify("");
  }, [lockCode]);

  const checkPassword = React.useCallback(async () => {
    const strd = localStorage.getItem("lockPassword");
    if ((await sha256(lockCode)) === strd) {
      localStorage.removeItem("lockPassword");
      onLockChange(false);
    } else {
      setError("Código incorrecto");
      setLockCode("");
    }
  }, [lockCode]);

  // Lock logic
  React.useEffect(() => {
    if (lockCode && lockCodeVerify) {
      if (lockCode === lockCodeVerify) {
        savePassword();
      } else {
        setError("Códigos no coinciden");
        setLockCode("");
        setLockCodeVerify("");
      }
    }
  }, [savePassword, lockCode, lockCodeVerify]);

  // Unlock logic
  React.useEffect(() => {
    if (storedPass && lockCode && !lockCodeVerify && !showCancel) {
      checkPassword();
    }
  }, [storedPass, checkPassword, lockCodeVerify, showCancel]);

  const onChange = async (value: string) => {
    setVal(value);

    if (error) {
      setError("");
    }

    if (value.length === AMOUNT && !lockCode) {
      setLockCode(value);
      setVal("");
    } else if (lockCode && value.length === AMOUNT) {
      setLockCodeVerify(value);
      setVal("");
    }
  };

  const onCancel = () => {
    setLockCode("");
    onLockChange(false);
  };

  return (
    <div className="flex w-full h-full bg-gray-300">
      <div className="flex flex-1 justify-center items-center">
        <div
          className="flex flex-col flex-1 justify-center items-center"
          style={{ height: 460 }}
        >
          <img className="w-64 mx-auto mb-10" src={logo} />
          <div className="h-32">
            {!lockCode && (
              <RICIBs
                amount={4}
                autoFocus
                handleOutputString={onChange}
                inputProps={{
                  className:
                    "2fa-box w-5 focus:outline-none focus:ring-red-600 ring-2",
                  placeholder: "_",
                  style: {
                    width: 100,
                    height: 100,
                  },
                }}
                inputRegExp={/^[0-9]$/}
              />
            )}
            {lockCode && !lockCodeVerify && (
              <RICIBs
                amount={4}
                autoFocus
                handleOutputString={onChange}
                inputProps={{
                  className:
                    "2fa-box w-5 focus:outline-none focus:ring-red-600 ring-2",
                  placeholder: "_",
                  style: {
                    width: 100,
                    height: 100,
                  },
                }}
                inputRegExp={/^[0-9]$/}
              />
            )}
          </div>
          <div className="h-12">
            {!lockCode && !error && showCancel && (
              <label className="text-gray-500 text-sm">
                Introduce tu código de bloqueo
              </label>
            )}
            {lockCode && !error && showCancel && !lockCodeVerify && (
              <label className="text-gray-500 text-sm">
                Confirma tu código de bloqueo
              </label>
            )}
            {!showCancel && !error && (
              <label className="text-gray-500 text-sm">
                Introduce tu código de bloqueo
              </label>
            )}
            {error && <label className="text-kiosk-red text-sm">{error}</label>}
          </div>
          <div>
            <img
              className="w-12 mx-auto mb-10"
              src={!showCancel ? lockIcon : unlockIcon}
            />
          </div>
          <div className="h-12">
            {showCancel && (
              <button
                type="button"
                className={`
                w-full inline-flex justify-center rounded-md border border-kiosk-red 
                shadow-sm px-4 py-2 bg-white text-base font-medium text-kiosk-red focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              `}
                onClick={onCancel}
              >
                Cancelar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockScreen;
