import { DateTime } from "luxon";
import crypto from "crypto-browserify";
import { SERVICE_TYPE } from "../constants";

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const generateDates = ({
  days,
  future = true,
  startIsoDate,
}: {
  days: number;
  future?: boolean;
  startIsoDate: string;
}) => {
  let dates: string[] = [];

  if (future) {
    dates.push(startIsoDate);
  }

  for (let x = 0; x < days; x++) {
    let dateString;

    if (future) {
      dateString = DateTime.fromISO(startIsoDate.replaceAll("-", ""))
        .plus({ day: x + 1 })
        .toISODate();
    } else {
      dateString = DateTime.fromISO(startIsoDate.replaceAll("-", ""))
        .minus({ day: x + 1 })
        .toISODate();
    }

    dates.push(dateString);
  }

  if (!future) {
    dates = dates.reverse();
    dates.push(startIsoDate);
  }

  return dates;
};

export const readeableDate = (isoDate: string) => {
  let scheduleDate = DateTime.fromISO(isoDate).toLocaleString({
    ...DateTime.DATE_MED_WITH_WEEKDAY,
    weekday: "long",
    year: "2-digit",
  });
  scheduleDate = scheduleDate
    .substring(0, scheduleDate.lastIndexOf(","))
    .replaceAll(",", "");

  return scheduleDate;
};

export const hourDiff = (date1: DateTime, date2: DateTime) => {
  return Math.round(date2.diff(date1, "hours").hours);
};

export const deepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const generateRandomIntegerInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getLocale = () =>
  navigator.language || (navigator as any)["userLanguage"];

export const serviceNameResolver = (type: string) => {
  let name;

  switch (type) {
    case SERVICE_TYPE.CELLPHONE_MINUTES:
      name = "Venta de tiempo aire";
      break;
    case SERVICE_TYPE.SERVICES_PAYMENTS:
      name = "Pago de servicios";
      break;
    case SERVICE_TYPE.TICKET:
      name = "Venta de boletos";
      break;
    case SERVICE_TYPE.ENTERTAINMENT:
      name = "Entretenimiento";
      break;
    case SERVICE_TYPE.INSURANCE:
      name = "Seguros";
      break;
    default:
      name = "Otros";
      break;
  }

  return name;
};

export const resolveFileUploads = (path: string) => {
  return `${process.env.UPLOAD_BE_BASE_PATH}${path}`;
};

export const generateRandomString = (length: number) => {
  // 32 max.
  return crypto.randomBytes(16).toString("hex").substring(0, length);
};

export const selectMenuStyle = (base: any) => ({
  ...base,
  "::-webkit-scrollbar": {
    width: 30,
    height: "0px",
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});
