import * as React from 'react';

type MyProps = {
};

type MyState = {
  hasError: boolean; // like this
};

class ErrorBoundary extends React.Component<MyProps, MyState>{
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch = (error: any, info: any) => {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
      <div className="flex w-full h-full items-center justify-center">
        <h1 className="text-2xl text-gray-400">Ocurrio un problema!</h1>
      </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;