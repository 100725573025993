import * as React from 'react';
import { DateTime } from 'luxon';
import { hourDiff, readeableDate } from 'libs';
import { ScheduleType, TicketCategoryType } from 'types';
import { BUS_SERVICE_ICON_SET, TICKET_CATEGORY_TYPE_ENUM } from '../../../constants';


export interface ScheduleDetailRowProps {
  schedule: ScheduleType,
  tickets: TicketCategoryType[],
  onSelect: (schedule: ScheduleType, notAvaibleTicketMessage?: string) => void
}

const ScheduleDetailRow: React.FC<ScheduleDetailRowProps> = ({ schedule, onSelect, tickets }) => {
  const { _departure_date, _arrival_date, _arrival_time, _departure_time } = schedule;

  const dateDeparture = DateTime.fromFormat(`${_departure_date}${_departure_time}`, 'yyyyMMddT');
  const dateArrival = DateTime.fromFormat(`${_arrival_date}${_arrival_time}`, 'yyyyMMddT');
  const [notAvaibleTicket, setNotAvaibleTicket] = React.useState<string>('');

  const getTotalBase = () => {
    // TODO: fix _value type since its string.
    return tickets.reduce((acc, ticket) => {
      return acc + parseFloat(`${schedule._category.find(cat => cat._id === TICKET_CATEGORY_TYPE_ENUM.C)?._value ?? 0}`) * ticket.quantity;
    }, 0);
  }
  
  const getTotal = () => {
    // TODO: fix _value type since its string.
    return tickets.reduce((acc, ticket) => {
      return acc + parseFloat(`${schedule._category.find(cat => cat._id === ticket.type)?._value ?? 0}`) * ticket.quantity;
    }, 0);
  }

  React.useEffect(() => {
    let message = 'No disponibles';
    const tempTickets = tickets.filter(ticket => ticket.quantity > 0);
    const availableTikcets = tempTickets.every(tt => {
      const sch = schedule._category.find(cat => cat._id === tt.type);
      const available = sch?._remain > 0;

      if(!available){
        message = `${message} ${tt.title}`;
      }

      return available;
    });


    if(!availableTikcets){
      setNotAvaibleTicket(message);
    }

  }, [tickets, schedule]);

  return (
    <div className="flex w-full p-2 border-gray-200 border-b border-t">

      <div className="w-1/4 flex flex-row justify-center items-center">
        <div className="flex flex-col text-center text-tufesa-main">
          <span>{schedule._service}</span>
          <img src={schedule._service === 'PLUS' ? BUS_SERVICE_ICON_SET.PLUS : BUS_SERVICE_ICON_SET.TITANIUM} alt="Tipo de servicio" />
        </div>
      </div>


      <div className="w-2/4 flex flex-col justify-center items-center">

        <div className="flex flex-row w-full">
          <div className="flex w-1/4 justify-center items-center">
            <div className="flex flex-col text-gray-600 items-center">
              <div className="text-sm">{readeableDate(_departure_date)}</div>
              <div className="text-lg font-bold">{_departure_time}</div>
              <div className="text-sm"></div>
            </div>
          </div>

          <div className="w-2/4 flex items-center">
            <svg width="100%" height="5"><line x1="1" y1="0" x2="100%" y2="0" stroke="#fe5a1f" style={{ strokeWidth: 4 }} /></svg>
          </div>

          <div className="flex w-1/4 justify-center items-center">
            <div className="flex flex-col text-gray-600 items-center">
              <div className="text-sm">{readeableDate(_arrival_date)}</div>
              <div className="text-lg font-bold">{_arrival_time}</div>
              {/* <div className="text-sm">{` `}</div> */}
            </div>
          </div>
        </div>

        <div className="flex w-full">
          <div className="w-1/4 text-center text-gray-600">
            {hourDiff(dateDeparture, dateArrival)}Hrs
          </div>
          <div className="w-2/4" />
          <div className="w-1/4 text-center text-tufesa-light hover:text-tufesa-main cursor-pointer">Detalles</div>
        </div>

      </div>


      <div className="flex w-1/4 justify-center">
        <div className="flex flex-col justify-center">
          {/* <div className="text-tufesa-main text-center text-lg">${schedule._category.find(cat => cat._id === TICKET_CATEGORY_TYPE_ENUM.C)?._value} MXN</div> */}
          <div className="flex flex-col text-tufesa-main text-center text-lg">
            {getTotal() !== getTotalBase() && (<span className="text-xs text-gray-500 line-through">${getTotalBase()} MXN</span>)}
            <span>${getTotal()} MXN</span>
          </div>
          <div className="flex w-full h-8 justify-center">
            <span
              className="flex cursor-pointer inset-y-1/2 bg-tufesa-dark hover:bg-tufesa-light text-white rounded-full h-8 w-8 justify-center items-center text-md"
              onClick={() => onSelect(schedule, notAvaibleTicket)}
            >
              {`>`}
            </span>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ScheduleDetailRow;