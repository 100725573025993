import * as React from "react";
import NumberFormat from "react-number-format";
import { DateTime } from "luxon";
import Select from "react-select";
import ScrollContainer from "react-indiana-drag-scroll";

import { resolveFileUploads, selectMenuStyle } from "libs";
import { PAYMENT_TYPE, SYSTEMATIC_ROUTER_ENUM } from "../../constants";
import { SERVICE_TYPE } from "../../constants";
import scanIcon from "assets/img/icon-scan.png";
import { ExternalServieType, ServiceItemType } from "types";
import { useModal } from "components/UI/Modal/ModalProvider";
import { getExternalServiceDetailsService } from "services/ServiceService";
import { createExternalServiceOrderService } from "services/OrderService";
import AppContext from "AppContext";
import LoadingModal from "components/PaymentController/LoadingModal";
import NumberPad, { NumberPadVariant } from "./NumberPad";
import { Spinner } from "components/UI";
import ConfirmPhoneNumberModal from "./ConfirmPhoneNumberModal";
import MessageModal from "components/UI/Message/MessageModal";
import CardPaymentModal from "components/CardPaymentModal";
import ConfirmModal from "./ConfirmModal";

interface PaymentControllerProps {}

const PaymentController: React.FC<PaymentControllerProps> = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const clearButtonRef = React.useRef<HTMLButtonElement>(null);
  const cardPaymentModal = useModal(CardPaymentModal);
  const loadingModal = useModal(LoadingModal);
  const messageModal = useModal(MessageModal);
  const confirmationModal = useModal(ConfirmPhoneNumberModal);
  const confirmModal = useModal(ConfirmModal);
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const { selectedService, back, session, paymentType } = mainState;
  const [mode, setMode] = React.useState<NumberPadVariant>(
    NumberPadVariant.reference
  );
  const [selectedItem, setSelectedItem] =
    React.useState<{ label: string; value: ServiceItemType }>(null);
  const [items, setItems] = React.useState<
    { label: string; value: ServiceItemType }[]
  >([]);
  const [refCode, setRefCode] = React.useState<string>("");
  const [amount, setAmount] = React.useState<string>("");
  const [serviceDetails, setServiceDetails] =
    React.useState<ExternalServieType>(null);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [scanningMode, setScanningMode] = React.useState<boolean>(false);
  const [currentTimeOut, setCurrentTimeOut] =
    React.useState<ReturnType<typeof setTimeout>>(null);
  const isPaymentService =
    selectedService.type === SERVICE_TYPE.SERVICES_PAYMENTS;
  const isCellphoneMinutes =
    selectedService.type === SERVICE_TYPE.CELLPHONE_MINUTES;
  const isAmount = mode === NumberPadVariant.amount;
  const enableContinue =
    !requesting && isPaymentService ? !!refCode || !!amount : !!selectedItem;

  const [comissionTotal, setComissionTotal] = React.useState<number>(0);
  const [grandTotal, setGrandTotal] = React.useState<number>(0);
  const overrideComissionSettings = session.place.settings?.overrideComission;

  React.useEffect(() => {
    setItems(
      selectedService.items.map((si) => ({ label: si.item.name, value: si }))
    );
    if (isCellphoneMinutes) {
      setMode(NumberPadVariant.amount);
    }

    let price = getPrice();
    const comission = getComission(price);
    let grandComission = comission;

    if (paymentType === PAYMENT_TYPE.CARD) {
      grandComission = comission + price;
      const cardComission = session.params.find(
        (param) => param.key === "card_comission"
      );

      grandComission = grandComission * parseFloat(cardComission?.value || "0");
      grandComission += comission;
    }

    setComissionTotal(!price ? 0 : grandComission);
    setGrandTotal(!price ? 0 : price + grandComission);
  }, [
    selectedService,
    serviceDetails,
    isPaymentService,
    selectedItem,
    session,
    amount,
  ]);

  React.useEffect(() => {
    setScanningMode(selectedService?.settings?.scanOnly ?? false);
  }, [selectedService.settings]);

  const getPrice = () => isPaymentService
    ? parseFloat(amount || "0")
    : selectedItem?.value?.price || 0;

  const getComission = (price: number) => {
    return overrideComissionSettings
      ? session.place.settings?.comission +
          price * session.place.settings?.comissionPercent
      : selectedService.settings.comission +
          price * selectedService.settings.comissionPercent;
  };
  
  const getExternalService = async (value: string) => {
    loadingModal({ modalButtonRef, message: "Solicitando información" });

    try {
      const res = (await getExternalServiceDetailsService({
        serviceUUID: selectedService.uuid,
        code: value,
      })) as ExternalServieType;

      modalButtonRef.current.click();

      setScanningMode(false);

      setServiceDetails({
        ...res,
      });
      setMode(NumberPadVariant.amount);


      let price = res.amount
      const comission = getComission(price);
      let grandComission = comission;

      if (paymentType === PAYMENT_TYPE.CARD) {
        grandComission = comission + price;
        const cardComission = session.params.find(
          (param) => param.key === "card_comission"
        );

        grandComission = grandComission * parseFloat(cardComission?.value || "0");
        grandComission += comission;
      }

      setComissionTotal(!price ? 0 : grandComission);
      setGrandTotal(!price ? 0 : price + grandComission);

      clearButtonRef.current.click();

      setAmount(`${price}`);

    } catch ({ message }) {
      modalButtonRef?.current?.click();
      await messageModal({
        message,
      });
      back(context);
    }
  };

  const createExternalServiceOrder = async () => {
    let createData;
    let payloadData = {};

    // Show card parment modal
    if (paymentType === PAYMENT_TYPE.CARD) {
      let serviceName: string = "";

      if (isCellphoneMinutes) {
        serviceName = selectedItem.value.item.name;
      } else {
        serviceName = selectedService.name;
      }

      if (isCellphoneMinutes) {
        // we use the amount as the actual phonenumber when prepaid.
        const res = await confirmationModal({ phoneNumber: amount });

        if (!res) {
          return;
        }
      }

      const result = await cardPaymentModal({
        username: session.username,
        reference: `${refCode}${DateTime.now().toFormat("yyMMddmmss")}`,
        amount: grandTotal,
        serviceName,
      });

      if (result.message === "success") {
        payloadData = {
          ...payloadData,
          extraData: result.data,
        };
      } else {
        return;
      }
    }else {
      if (isCellphoneMinutes) {
        // we use the amount as the actual phonenumber when prepaid.
        const res = await confirmationModal({ phoneNumber: amount });

        if (!res) {
          return;
        }
      }
    }

    if (isPaymentService) {
      payloadData = {
        ...payloadData,
        code: refCode,
      };
      createData = {
        paymentType: mainState.paymentType,
        tokenUUID: mainState.account?.token?.uuid,
        serviceUUID: mainState.selectedService.uuid,
        amount: grandTotal,
        payload: payloadData,
      };
    } else {
      payloadData = {
        ...payloadData,
        code: amount, // phone number
      };
      createData = {
        paymentType: mainState.paymentType,
        tokenUUID: mainState.account?.token?.uuid,
        serviceUUID: mainState.selectedService.uuid,
        serviceItemUUID: selectedItem.value.uuid,
        amount: grandTotal,
        payload: payloadData,
      };
    }

    if (!isCellphoneMinutes) {
      const { data } = await confirmModal();
      if (data !== "success") {
        return;
      }
    }

    if(!requesting) {
      setRequesting(true);

      loadingModal({ modalButtonRef, message: "Realizando solicitud" });

      try {
        const payloadString: any = await createExternalServiceOrderService(
          createData
        );
        setRequesting(false);
        modalButtonRef.current.click();

        setAppState({
          ...context,
          mainState: {
            ...mainState,
            currentComponent: SYSTEMATIC_ROUTER_ENUM.PRINT,
            externalServiceOrder: JSON.parse(payloadString),
          },
        });
      } catch ({ message }) {
        setRequesting(false);
        modalButtonRef.current.click();
        messageModal({ message });
        // back(context);
      }
    }
  };

  const onContinueHandler = async () => {
    if (mode === NumberPadVariant.reference) {
      await getExternalService(refCode);
      setMode(NumberPadVariant.amount);

      // if (selectedService.type === SERVICE_TYPE.SERVICES_PAYMENTS) {
      clearButtonRef.current.click();
      // }
    } else if (mode === NumberPadVariant.amount) {
      await createExternalServiceOrder();
    }
  };

  const onNumpadChangeHandler = async (value: string) => {
    if (mode === NumberPadVariant.reference) {
      setRefCode(value);
    } else if (mode === NumberPadVariant.amount) {
      setAmount(value);
    }
  };

  const onChangeHanlder = (e: React.SyntheticEvent) => {
    const form = e.currentTarget as HTMLInputElement;
    const { value } = form;

    setRefCode(value);

    if (isPaymentService && scanningMode) {
      clearTimeout(currentTimeOut);
      const timeOut = setTimeout(
        async () => await getExternalService(value),
        500
      );
      setCurrentTimeOut(timeOut);
    }
  };

  const onFocusLost = () => {
    if (inputRef) {
      inputRef.current.focus();
    }
  };

  const onClearHandler = () => {
    if (mode === NumberPadVariant.reference) {
      setRefCode("");
    } else if (mode === NumberPadVariant.amount) {
      setAmount("");
    }
  };

  const getTitle = () => {
    if (isCellphoneMinutes) {
      return "Número teléfonico";
    } else if (isPaymentService && !isAmount) {
      return selectedService.settings?.inputMask;
    } else if (isPaymentService && isAmount) {
      return "Ingresa cantidad a pagar de su recibo";
    }
  };

  return (
    <div className="flex flex-col w-full">
      {/* <h1 className="text-5xl text-center font-myriad-bold">
        Detalles de cuenta
      </h1> */}
      <div className="flex flex-col-reverse sm:flex-row space-x-2 flex-1 pt-6">
        {isAmount && (
          <div className="flex flex-col w-full sm:w-1/2 mt-6 sm:mt-0">
            <h1 className="block text-3xl font-bold text-gray-700 mb-4 text-center my-4">
              Detalles
            </h1>
            <ScrollContainer
              className="flex flex-col border border-gray-300 rounded-md"
              style={{ maxHeight: 600 }}
            >
              <div className="flex flex-row text-2xl px-4 py-2 border-b border-gray-300 items-center bg-gray-300">
                <div className="w-1/2 font-bold">Servicio:</div>
                <div className="w-1/2">
                  <img
                    className="h-12 mx-auto rounded"
                    src={resolveFileUploads(selectedService.imageUrl)}
                    alt={`${selectedService.name} logo`}
                  />
                </div>
              </div>

              {isCellphoneMinutes && (
                <>
                  <div className="flex flex-col text-2xl p-4 border-b border-gray-300 h-auto py-4">
                    <span className="w-1/2 font-bold mb-2">
                      Selecciona recarga:{" "}
                    </span>
                    <Select
                      id="items"
                      defaultValue={null}
                      value={selectedItem}
                      name="items"
                      placeholder="Selecciona un item"
                      options={items}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={{
                        menuList: (base) => ({
                          ...base,
                          ...selectMenuStyle(base),
                          height: 250,
                        }),
                      }}
                      onChange={(value) => setSelectedItem(value)}
                    />
                  </div>
                </>
              )}

              {requesting ? (
                <Spinner className="w-12 h-12 text-kiosk-red" />
              ) : (
                <>
                  {!isCellphoneMinutes && (
                    <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                      <span className="w-1/2 font-bold">
                        Número de referencia:{" "}
                      </span>
                      <span
                        className={`w-1/2 ${
                          refCode.length > 25 ? "text-base" : ""
                        }`}
                      >
                        {refCode}
                      </span>
                    </div>
                  )}

                  {selectedItem && (
                    <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                      <span className="w-full">
                        {selectedItem.value.item.details}
                      </span>
                    </div>
                  )}

                  <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                    <span className="w-1/2 font-bold">Fecha: </span>
                    <span className="w-1/2">
                      {DateTime.now().toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )}
                    </span>
                  </div>

                  <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                    <span className="w-1/2 font-bold">Subtotal: </span>
                    <span className="w-1/2">
                      <NumberFormat
                        // value={serviceDetails.amount}
                        value={
                          isCellphoneMinutes
                            ? selectedItem
                              ? selectedItem.value.price
                              : 0
                            : serviceDetails?.amount || amount || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    </span>
                  </div>
                  <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                    <span className="w-1/2 font-bold">
                      Comisón y cuota de servicio:{" "}
                    </span>
                    <span className="w-1/2">
                      {`$${comissionTotal.toFixed(2)}`}
                    </span>
                  </div>

                  <div className="flex flex-row text-2xl p-4 border-b border-gray-300">
                    <span className="w-1/2 font-bold">Cantidad total a pagar: </span>
                    <span className="w-1/2">
                      <NumberFormat
                        value={grandTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    </span>
                  </div>
                </>
              )}
            </ScrollContainer>
          </div>
        )}

        <div
          className={`flex flex-col w-full ${isAmount ? "sm:w-1/2" : "sm:w-4/6 mx-auto"}`}
        >
          {!scanningMode && (
            <NumberPad
              value={isAmount ? amount : refCode}
              clearButtonRef={clearButtonRef}
              mode={mode}
              placeholder={
                !isAmount ? selectedService.settings?.inputMask : null
              }
              title={
                <>
                  {selectedService.settings?.codeScan && !isAmount && (
                    <div className="flex flex-row items-center">
                      <span>Presiona aquí para escanear tu recibo</span>
                      <span className="ml-4">
                        <img
                          onClick={() => setScanningMode(true)}
                          className="h-12 mx-auto cursor-pointer animate-bounce"
                          src={scanIcon}
                          alt="escanear recibo"
                        />
                      </span>
                    </div>
                  )}
                  {isAmount && getTitle()}
                </>
              }
              enableContinueRule={enableContinue}
              onContinue={onContinueHandler}
              onChange={onNumpadChangeHandler}
              onClear={onClearHandler}
            />
          )}

          {scanningMode && (
            <div className="flex h-full items-center justify-center">
              <div className="flex flex-col text-center">
                <img
                  className="h-52 mx-auto mb-4"
                  src={scanIcon}
                  alt="escanear token"
                />
                <span className="text-4xl">Escanea código de barras</span>
              </div>
            </div>
          )}

          {!amount && scanningMode && (
            <input
              autoFocus
              ref={inputRef}
              value={refCode}
              onChange={onChangeHanlder}
              onBlur={onFocusLost}
              style={{ height: 0 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentController;
