import { PAYMENT_TYPE } from "../constants";

export * from "./TufesaTypes";

export interface ComponentNameType {
  name: string;
  position: number;
}

export interface AppStateType {
  currentComponentPosition: number;
}

export enum ROLES {
  USER = "user",
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  AUDIT = "audit",
}

export enum PERMISSIONS {
  POS = "pos",
  KIOSK = "kiosk",
  TOKEN = "token",
}

export enum DEVICE_PAYMENT_METHOD {
  CARD = "card",
  TOKEN = "token",
}

export interface City {
  timeZone: string;
}

export interface PlaceSettings {
  comission: number;
  comissionPercent: number;
  overrideComission: boolean;
}
export interface Place {
  city: City;
  settings: PlaceSettings;
}

export interface DeviceSettings {
  paymentMethod: DEVICE_PAYMENT_METHOD[];
}

export interface Device {
  settings: DeviceSettings;
}

export interface SessionType {
  uuid: string;
  username: string;
  roles: ROLES[];
  permissions: PERMISSIONS[];
  params?: Param[];
  place?: Place;
  device?: Device;
}

export interface ServiceType {
  uuid: string;
  name: string;
  type: string;
  imageUrl: string;
  settings: ServiceSettingsType;
  items?: ServiceItemType[];
  // Mostly used to store external provider data or assign data dynamically.
  metadata?: Chubb;
}

export interface ServiceSettingsType {
  comission: number;
  comissionPercent: number;
  daysBeforeExpire: number;
  printDisclaimer: string;
  inputMask: string;
  codeScan: boolean;
  scanOnly: boolean;
}

export interface ServiceItemType {
  uuid: string;
  name: string;
  item: ItemType;
  price: number;
  details: string;
}

export interface ItemType {
  uuid: string;
  name: string;
  sku: string;
  details: string;
  imageUrl: string;
}

export interface TokenType {
  id: string;
  uuid: string;
  points: number;
}

export interface ServiceMenuItemType {
  type: string;
  icon: string;
  name: string;
}

export interface TokenFetchType {
  id?: string;
  tokenUUID?: string;
}

export interface WalletOperation {
  reference: string;
  points: number;
  description?: string;
}

export interface WalletOperationResponse {
  message: string;
  ticket: string;
}

export type GenerateToken = Omit<WalletOperation, "description">;

export interface GenerateTokenResponse {
  points: number;
  qrToken: string;
  barToken: string;
  expiresAt: string;
  service: ServiceType;
}

export enum ACTION_TYPE {
  ADD = "add",
  TAKE = "take",
}

export interface AccountType {
  token?: TokenType;
}

type ChubPayload = {
  packageId: number;
  days: number;
};

type Payload = {
  code?: string;
  email?: string;
  phone?: string;
  chubb?: ChubPayload;
};

export interface CreateExternalServiceOrderType {
  paymentType: PAYMENT_TYPE;
  serviceUUID: string;
  serviceItemUUID?: string;
  tokenUUID?: string;
  amount?: number;
  payload?: Payload;
}

export interface ExternalServiceDetailsRequestType {
  serviceUUID: string;
  code: string;
}

export interface ExternalServieType {
  code: string;
  expireDate: string;
  amount: number;
  paid: boolean;
}
export interface ExternalServiceOrderType {
  items: {
    service: string;
    code: string;
    comission: number;
    total: number;
  }[];
  auth: string;
  orderNumber: string;
  paidAt: string;
  user: string;
  device: string;
  place: string;
  city: string;
  printDisclaimer: string;
  ticketHtml?: string;
}

type ChubbPayload = {
  packageId: number;
  days: number;
};

export interface InsuranceType {
  email: string;
  phone: string;
  chubb?: ChubbPayload;
}

export interface Param {
  uuid: string;
  key: string;
  value: string;
  details: string;
  type: string;
}

export interface UserType {
  uuid: string;
  username: string;
  rolesPermissions: RolePermissionsType;
}

interface RolePermissionsType {
  roles: ROLES[];
  permissions: PERMISSIONS[];
}
export interface LoginResponse {
  user: UserType;
  username: string;
  place: Place;
  params: Param[];
}

export interface Session {
  loginFetched?: boolean;
}

export interface CutReport {
  message: string;
  ticketHtml: string;
}

export type PaginationParams = {
  input: {
    page: number;
    uuid?: string;
    query?: string;
  };
};

export enum ORDER_TYPE {
  SERVICE = "service",
  ITEM = "item",
}
export interface Order {
  uuid: string;
  orderNumber: string;
  details: string;
  amount: number;
  paymentType: PAYMENT_TYPE;
  status: Order;
  reference: string;
  documentUrl?: string;
  refItems?: RefItem[];
  paidAt: Date;
  updatedAt: Date;
}

export interface CutOrder {
  deviceName: string;
  timeZone: string;
  grandTotal: number;
  grandTotalComission: number;
  grandTotalWithoutComission: number;
  orders: CutOrderItems[];
}

export interface CutOrderItems {
  serviceName: string;
  totalServiceOrders: number;
  serviceTotalWithoutComission: number;
  serviceTotal: number;
}
export interface RefItem {
  serviceName?: string;
  serviceType?: string;
  cardComission?: number;
  serviceComission?: number;
  metadata?: any;
  quantity?: number;
  price?: number;
}

export type ChubbOption = {
  netPremium: number;
  resources: number;
  persons: number;
  serviceAssistence: number;
  medicalExpenses: number;
  assets: number;
  miscellaneous: number;
  days: number;
};

export type ChubbData = {
  packageId: number;
  packageName: string;
  description: string;
  activationCodeExpirationDays: number;
  options: ChubbOption[];
};

export type Chubb = {
  data: ChubbData[];
};
