import { apolloClient } from "libs/apolloClient";
import { gql } from "@apollo/client";
import ErrorHandler from "libs/ApolloErrorHandler";

export const loginService = (
  username: string,
  password: string,
  deviceCode: string
) => {
  return apolloClient()
    .query({
      query: gql`
        query deviceLogin($input: DeviceLoginInput!) {
          deviceLogin(input: $input) {
            token
            user {
              uuid
              username
              rolesPermissions {
                roles
                permissions
              }
            }
            device {
              place {
                city {
                  timeZone
                }
                settings {
                  comission
                  comissionPercent
                  overrideComission
                }
              }
              settings {
                paymentMethod
              }
            }
            params {
              key
              value
              type
            }
          }
        }
      `,
      variables: {
        input: {
          username,
          password,
          deviceCode,
        },
      },
    })
    .then(({ data }): Object => {
      const deviceLogin = data.deviceLogin;

      return {
        ...deviceLogin,
      };
    })
    .catch((err) => {
      return err;
    });
};

export const tokenLoginService = () => {
  return apolloClient()
    .query({
      query: gql`
        query tokenLogin {
          tokenLogin(isDevice: true) {
            user {
              uuid
              username
              rolesPermissions {
                roles
                permissions
              }
            }
            place {
              city {
                timeZone
              }
              settings {
                comission
                comissionPercent
                overrideComission
              }
            }
            params {
              key
              value
              type
            }
            device {
              settings {
                paymentMethod
              }
            }
          }
        }
      `,
    })
    .then(({ data }): Object => {
      const tokenLoginData = data.tokenLogin;

      return {
        ...tokenLoginData,
      };
    });
};

export const logoutService = () => {
  return apolloClient().query({
    query: gql`
      query logout {
        logout
      }
    `,
  })
  .then(res => ErrorHandler(res));
};
