import * as React from 'react';

import { PAYMENT_TYPE, SYSTEMATIC_ROUTER_ENUM } from './constants';
import { 
	AccountType,
	DestinationType, 
	ExternalServiceOrderType, 
	InsuranceType, 
	OriginType, 
	PageViewType, 
	ScheduleType, 
	ServiceItemType, 
	ServiceType, 
	SessionType, 
	StepViewType, 
	TicketCategoryType, 
	TicketType
} from 'types';

export interface TufesaAppStateType {
	// getters
	printing?: boolean,
	origin?: OriginType
	destination?: DestinationType,
	departureDate?: string,
	returnDate?: string,
	tickets?: TicketCategoryType[],
	phone?: string,
	email?: string,
	marketing?: boolean,
	pageView?: PageViewType,
	stepView?: StepViewType,
	isDirtyData?: boolean,
	departureSchedule?: ScheduleType,
	returnSchedule?: ScheduleType,
	choosingDepartureSeats?: boolean, // to seats for departure screen.
	choosingReturnSeats?: boolean, // to seats for return screen.
	departureTicket?: TicketType
	returnTicket?: TicketType
}

interface Location {
  type: string;
  coordinates: number[];
}
export interface MainStateType {
	locked?: boolean,
	session?: SessionType,
	location?: Location,
	services?: ServiceType[],
	currentComponent?: SYSTEMATIC_ROUTER_ENUM,
	selectedServiceType?: string,
	selectedService?: ServiceType,
	selectedServiceItem?: ServiceItemType,
	paymentType?: PAYMENT_TYPE,
	account?: AccountType,
	externalServiceOrder?: ExternalServiceOrderType,
	back?: (context: AppContextType) => void,
	insurance?: InsuranceType,
}

interface AppContextBaseType {
	tufesaState?: TufesaAppStateType,
	mainState?: MainStateType
}

export type AppContextType = AppContextBaseType & {
	setAppState?: (state: AppContextBaseType) => void
}

export const AppContext = React.createContext<AppContextType>({});


export default AppContext;