import * as React from "react";
import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalHeader from "components/UI/Modal/ModalHeader";

interface ConfirmModalProps {}

const ConfirmModal: React.FC<ConfirmModalProps & ModalProps> = ({
  onSubmit,
  onDismiss,
}) => {
  return (
    <Modal variant="md">
      <ModalHeader showCloseButon={false}>Confirmar</ModalHeader>
      <ModalBody>
        <div className="flex w-full">
          <div className="flex flex-col w-full text-center items-center justify-center">
            <p className="text-xl text-gray-600 font-bold mb-8">Proceder?</p>
            <div className="flex flex-row space-x-4 mt-4 justify-center">
              <button
                className="flex px-6 py-4 border rounded border-gray-400 bg-gray-600 items-center justify-center text-white uppercase"
                onClick={onDismiss}
              >
                Cancelar
              </button>
              <button
                className="flex px-6 py-4 border rounded bg-kiosk-red border-kiosk-red hover:bg-red-500 items-center justify-center text-white uppercase"
                onClick={() => onSubmit({ data: "success" })}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;
