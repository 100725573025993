import { apolloClient } from "libs/apolloClient";
import { gql } from "@apollo/client";
import { ExternalServiceDetailsRequestType } from "types";
import ErrorHandler from "libs/ApolloErrorHandler";

const getServiceByDevice = () => {
  return apolloClient()
    .query({
      query: gql`
        query getServicesByDevice {
          servicesByDevice {
            uuid
            imageUrl
            type
            settings {
              comission
              comissionPercent
              daysBeforeExpire
              inputMask
              codeScan
              scanOnly
            }
            name
            items {
              uuid
              price
              item {
                uuid
                details
                name
                imageUrl
                sku
              }
            }
            metadata {
              ... on Chubb {
                data {
                  packageId
                  packageName
                  description
                  activationCodeExpirationDays
                  options {
                    netPremium
                    resources
                    persons
                    serviceAssistence
                    medicalExpenses
                    assets
                    miscellaneous
                    days
                  }
                }
              }
            }
          }
        }
      `,
    })
    .then((res) => ErrorHandler(res))
    .then(({ data }): Object => {
      const itemsData = data.servicesByDevice;

      return itemsData;
    });
};

const getExternalServiceDetailsService = (
  externalServiceDetails: ExternalServiceDetailsRequestType
) => {
  return apolloClient()
    .query({
      query: gql`
        query externalServiceDetails($input: ExternalServiceDetailsInput!) {
          externalServiceDetails(input: $input) {
            code
            expireDate
            amount
            paid
          }
        }
      `,
      variables: {
        input: {
          ...externalServiceDetails,
        },
      },
    })
    .then((res) => ErrorHandler(res))
    .then(({ data, errors }): Object => {
      if (errors) {
        throw new Error(errors[0].message);
      }

      const externalServiceDetails = data.externalServiceDetails;

      return externalServiceDetails;
    });
};

export { getServiceByDevice, getExternalServiceDetailsService };
