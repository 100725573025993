import * as React from "react";
import * as ReactDOM from "react-dom";
import { Settings } from 'luxon';

import AppProvider from "./AppProvider";
import { getLocale } from "libs";

import 'assets/style/App.scss';
import 'assets/style/output.css';

Settings.defaultLocale = getLocale();

ReactDOM.render(
    <AppProvider />,
    document.getElementById("kioks-app")
);