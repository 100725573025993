import axios from "axios";
import * as React from "react";
import { DateTime } from "luxon";

import AppContext from "AppContext";
import { createExternalServiceOrderService } from "services/OrderService";
import { SeatRowType, TicketResponseType } from "types";
import { useModal } from "components/UI/Modal/ModalProvider";
import MessageModal from "./MessageModal";
import {
  PAGE_VIEW_ITEMS,
  PAGE_VIEW_LIST,
  STEP_VIEW_ITEMS,
  STEP_VIEW_LIST,
} from "constants/Tufesa";
import PrintTicket from "./PrintTicket";
import CardPaymentModal from "components/CardPaymentModal";
import { PAYMENT_TYPE } from "../../../constants";
import { generateRandomString } from "libs";

interface ResumeProps {}

const Resume: React.FC<ResumeProps> = () => {
  const context = React.useContext(AppContext);
  const messageModal = useModal(MessageModal);
  const cardPaymentModal = useModal(CardPaymentModal);
  const { tufesaState, mainState, setAppState } = context;
  const { selectedService, paymentType, session } = mainState;
  const { phone, email } = tufesaState;
  const [departureTotal, setDepartureTotal] = React.useState<number>(0);
  const [returnTotal, setReturnTotal] = React.useState<number>(0);
  const {
    origin,
    destination,
    departureTicket,
    returnTicket,
    departureSchedule,
    returnSchedule,
    tickets,
    departureDate,
    returnDate,
  } = tufesaState;
  const [seatsVerified, setSeatsVerified] = React.useState<boolean>(false);
  const [seatsOk, setSeatsOk] = React.useState<boolean>(false);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [paidTickets, setPaidTickets] = React.useState<TicketResponseType[]>(
    []
  );
  const [ticketHtml, setTicketHtml] = React.useState<string>(null);
  const [isPrinting, setIsPrinting] = React.useState<boolean>(false);
  const [comissionTotal, setComissionTotal] = React.useState<number>(0);
  const [grandTotal, setGrandTotal] = React.useState<number>(0);
  const [apiUrl, setApiUrl] = React.useState<string>("");
  const overrideComissionSettings = session.place.settings?.overrideComission;


  React.useEffect(() => {
    if (!apiUrl) {
      const api_url = session.params.find(
        (param) => param.key === "tufesa_api_url"
      ).value;
      setApiUrl(api_url);
    } else {
      let dTotal = tickets.reduce((acc, ticket) => {
        return (
          acc +
          departureSchedule._category.find((cat) => cat._id === ticket.type)
            ?._value *
            ticket.quantity
        );
      }, 0);

      if (returnSchedule) {
        const rTotal = tickets.reduce((acc, ticket) => {
          return (
            acc +
            returnSchedule._category.find((cat) => cat._id === ticket.type)
              ?._value *
              ticket.quantity
          );
        }, 0);
        setReturnTotal(rTotal);
      }

      let price = departureTotal + returnTotal;
      const comission = overrideComissionSettings
        ? session.place.settings?.comission +
          price * session.place.settings?.comissionPercent
        : selectedService.settings.comission +
          price * selectedService.settings.comissionPercent;

      let grandComission = comission;

      if (paymentType === PAYMENT_TYPE.CARD) {
        grandComission = comission + price;
        const cardComission = session.params.find(
          (param) => param.key === "card_comission"
        );

        grandComission =
          grandComission * parseFloat(cardComission?.value || "0");
        grandComission += comission;
      }

      setComissionTotal(grandComission);
      setGrandTotal(price + grandComission);
      setDepartureTotal(dTotal);

      if (!seatsVerified) {
        setSeatsVerified(true);
        verifySeatsAvailibility();
      }
    }
  }, [seatsVerified, departureTotal, returnTotal, session, apiUrl]);

  const verifySeatsAvailibility = async () => {
    let departureSeatAvailable = true;
    let returnSeatAvailable = true;

    if (departureTicket) {
      const depUrl = `${apiUrl}/seats?from=${origin.idField}&to=${
        destination.idField
      }&date=${departureDate.replaceAll("-", "")}&schedule=${
        departureSchedule._id
      }`;
      const { data }: any = await axios.get(depUrl);
      const dSeats = data?._Response?.dataField[0]?._row || [];

      const unavailableSeats = dSeats
        .map(({ _seat }: SeatRowType) =>
          _seat.filter((_s) => !_s._available).map((_s) => _s._id)
        )
        .flat();

      departureSeatAvailable = departureTicket.customers
        .map((c) => c.seat)
        .flat()
        .some((s) => {
          return !unavailableSeats.includes(s);
        });
    }

    if (returnTicket) {
      const retUrl = `${apiUrl}/seats?from=${destination.idField}&to=${
        origin.idField
      }&date=${returnDate.replaceAll("-", "")}&schedule=${returnSchedule._id}`;
      const { data }: any = await axios.get(retUrl);
      const rSeats = data?._Response?.dataField[0]?._row || [];

      const unavailableSeats = rSeats
        .map(({ _seat }: SeatRowType) =>
          _seat.filter((_s) => !_s._available).map((_s) => _s._id)
        )
        .flat();

      returnSeatAvailable = returnTicket.customers
        .map((c) => c.seat)
        .flat()
        .some((s) => {
          return !unavailableSeats.includes(s);
        });
    }

    const available = departureSeatAvailable && returnSeatAvailable;

    if (!available) {
      const { data } = await messageModal({
        message: `Uno de los asiento de ${
          !departureSeatAvailable ? "Ida" : ""
        } ${!returnSeatAvailable ? "Vuelta" : ""} ya no esta disponible.`,
      });
      if (data === "success") {
        setAppState({
          ...context,
          tufesaState: {
            ...tufesaState,
            pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.ASIENTOS],
            stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.ASIENTOS],
            choosingDepartureSeats: true,
            choosingReturnSeats: false,
            departureTicket: null,
            returnTicket: null,
          },
        });
      }
    } else {
      setSeatsOk(available);
    }
  };

  const onPayHanlder = async () => {
    const { departureTicket, returnTicket } = tufesaState;
    const tickets = [];

    setPaidTickets([]);

    if (departureTicket) {
      tickets.push({
        ...departureTicket,
        total: departureTotal,
        line: "TUFES",
        customers: {
          customer: departureTicket.customers,
        },
      });
    }

    if (returnTicket) {
      tickets.push({
        ...returnTicket,
        total: returnTotal,
        line: "TUFES",
        customers: {
          customer: returnTicket.customers,
        },
      });
    }

    let payloadData: any = {
      phone,
      email,
      tickets,
    };

    if (paymentType === PAYMENT_TYPE.CARD) {
      const code = `T${generateRandomString(12)}${DateTime.now().toFormat(
        "yyyyMMdd"
      )}`;
      const result = await cardPaymentModal({
        username: session.username,
        reference: code,
        amount: grandTotal,
        serviceName: selectedService.name,
      });

      if (result.message === "success") {
        payloadData = {
          ...payloadData,
          extraData: result.data,
        };
      } else {
        return;
      }
    }

    setRequesting(true);

    try {
      const payloadString: any = await createExternalServiceOrderService({
        paymentType: mainState.paymentType,
        tokenUUID: mainState.account?.token?.uuid,
        serviceUUID: mainState.selectedService.uuid,
        amount: grandTotal,
        payload: payloadData,
      });
      const res = JSON.parse(payloadString) as any;

      setIsPrinting(true);
      setTicketHtml(res.ticketHtml);
      setAppState({
        ...context,
        tufesaState: {
          ...tufesaState,
          printing: true,
        },
      });
    } catch (err) {
      setRequesting(false);
      setIsPrinting(false);
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col w-5/6 items-center mx-auto text-tufesa-main p-2">
      {isPrinting ? (
        <PrintTicket ticketHtml={ticketHtml} />
      ) : (
        <>
          <h1 className="text-2xl">Resumen</h1>
          <div className="flex flex-col">
            <div className="flex flex-col mt-2">
              <div className="flex">
                <div className="flex flex-col pr-4">
                  <span>
                    <b>Ida</b>
                  </span>
                  <span>{`${origin.descriptionField} - ${destination.descriptionField}`}</span>
                  <span>
                    <b>Salida</b>:{" "}
                    {DateTime.fromISO(
                      departureSchedule._departure_date
                    ).toFormat("EEE, dd LLL")}{" "}
                    - {departureSchedule._departure_time} Hrs
                  </span>
                  <span>
                    <b>Llegada</b>:{" "}
                    {DateTime.fromISO(departureSchedule._arrival_date).toFormat(
                      "EEE, dd LLL"
                    )}{" "}
                    - {departureSchedule._arrival_time} Hrs
                  </span>
                </div>
                <div className="flex flex-col pl-4">
                  <div className="">
                    <b>Servicio</b>: {departureSchedule._service}
                  </div>
                  {/* <div className=""><b>Autobús</b>: ???</div> */}
                  {/* <div className=""><b>Escalas</b>: "needs api call"</div> */}
                  <div className="">
                    <b>Tipo de salida</b>: Local
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 text-center">
                <span className="mx-auto font-bold">Asientos</span>
                <div className="flex flex-row justify-center">
                  {departureTicket.customers.map((customer) => (
                    <span
                      key={`departure-${customer.seat}`}
                      className="flex items-center justify-center border border-tufesa-main rounded-full w-8 h-8 m-1"
                    >
                      {customer.seat}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {returnSchedule && (
              <div className="flex flex-col mt-2">
                <div className="flex">
                  <div className="flex flex-col pr-4">
                    <span>
                      <b>Vuelta</b>
                    </span>
                    <span>{`${destination.descriptionField} - ${origin.descriptionField}`}</span>
                    <span>
                      <b>Salida</b>:{" "}
                      {DateTime.fromISO(
                        returnSchedule._departure_date
                      ).toFormat("EEE, dd LLL")}{" "}
                      - {returnSchedule._departure_time} Hrs
                    </span>
                    <span>
                      <b>Llegada</b>:{" "}
                      {DateTime.fromISO(returnSchedule._arrival_date).toFormat(
                        "EEE, dd LLL"
                      )}{" "}
                      - {returnSchedule._arrival_time} Hrs
                    </span>
                  </div>

                  <div className="flex flex-col pl-4">
                    <div className="">
                      <b>Servicio</b>: {departureSchedule._service}
                    </div>
                    <div className="">
                      <b>Autobús</b>: ???
                    </div>
                    <div className="">
                      <b>Escalas</b>: "needs api call"
                    </div>
                    <div className="">
                      <b>Tipo de salida</b>: De paso
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-4 text-center">
                  <span className="mx-auto font-bold">Asientos</span>
                  <div className="flex flex-row justify-center">
                    {returnTicket.customers.map((customer) => (
                      <span
                        key={`return-${customer.seat}`}
                        className="flex items-center justify-center border border-tufesa-main rounded-full w-8 h-8 m-1"
                      >
                        {customer.seat}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-8 text-2xl">
            <b>Comisón y cuota de servicio</b>: {comissionTotal.toFixed(2)} MXN
          </div>
          <div className="mt-2 text-2xl">
            {/* <b>Total</b>: {`$${comission + (departureTotal + returnTotal)}`}MXN */}
            <b>Total</b>: {`$${grandTotal}`}MXN
          </div>
          <div className="flex flex-row w-5/6 mt-2">
            <button
              className={`border text-white ${
                seatsOk
                  ? "border-tufesa-main bg-tufesa-dark hover:bg-tufesa-main"
                  : "bg-gray-300"
              } rounded-full py-2 px-12 mx-auto`}
              onClick={() => (seatsOk && !requesting ? onPayHanlder() : null)}
            >
              {!requesting ? "Pagar" : "Procesando..."}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Resume;
