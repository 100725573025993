export * from './Tufesa';

export enum SYSTEMATIC_ROUTER_ENUM {
  LOGIN = "login",
  SCAN = "scan",
  PAYMENT_TYPE = "payment_type",
  WELCOME = "welcome",
  MENU_SERVICES = "menu_services",
  SERVICE = "service",
  PAYMENT_CONTROLLER = "payment_controller",
  PRINT = "print",
  INSURANCE = "insurance",
  INSURANCE_LIST = "insurance_list",
  INSURANCE_PRINT = "insurance_print",
  CUT = "cut",
  TOKEN = "token",
  // External
  TUFESA = "tufesa"
};

export enum SERVICE_TYPE {
  SERVICES_PAYMENTS = "services_payments",
  CELLPHONE_MINUTES = "cellphone_minutes",
  ENTERTAINMENT = "entretainment",
  TICKET = "ticket",
  INSURANCE = "insurance",
  OTHER = "other",
};

export enum PAYMENT_TYPE {
  TOKEN = "token",
  CARD = "card",
  CASH = "cash",
};
