import { apolloClient } from "libs/apolloClient";
import { gql } from "@apollo/client";
import { CreateExternalServiceOrderType } from "types";
import { deepCopy } from "libs";
import ErrorHandler from "libs/ApolloErrorHandler";

const createExternalServiceOrderService = (
  createExternalDevice: CreateExternalServiceOrderType
) => {
  return apolloClient()
    .mutate({
      mutation: gql`
        mutation createServiceOrder($input: CreateServiceOrderInput!) {
          createServiceOrder(input: $input)
        }
      `,
      variables: {
        input: {
          ...createExternalDevice,
        },
      },
    })
    .then(res => ErrorHandler(res))
    .then(({ data }): Object => {
      const orderData = data.createServiceOrder;

      return orderData;
    });
};

const cutOrdersService = () => {
  return apolloClient()
    .query({
      query: gql`
        query cutOrders {
          cutOrders {
            deviceName
            timeZone
            grandTotal
            grandTotalComission
            grandTotalWithoutComission
            orders {
              serviceName
              totalServiceOrders
              serviceTotal
              serviceTotalWithoutComission
            }
          }
        }
      `,
    })
    .then((res) => deepCopy(res))
    .then(({ data }): Object => {
      const cutOrdersData = data.cutOrders;

      return {
        ...cutOrdersData,
      };
    });
};

export { createExternalServiceOrderService, cutOrdersService };
