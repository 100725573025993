import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";
// import { Session } from "types";
import { getLocation } from "./location";

//  Graphql setup.
const httpLink = createHttpLink({
	uri: process.env.GRAPHQL_API_URL,
	credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let msg: string;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      msg = `${msg || ''} ${message}`
    });
  }

  if (networkError) {
    // msg = `${msg || ''} ${networkError}`
    msg = `${msg || ''}`
  }

  toast.error(msg);
});

const apolloClient = () => {

	const authLink = setContext(async (_, { headers }) => {
		return {
			headers: {
				...headers,
				'x-location': await getLocation()
			}
		}
	})

	const client = new ApolloClient({
		link: from([errorLink, authLink.concat(httpLink)]),
		cache: new InMemoryCache(),
		defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
	});

	return client;
}

export {
	apolloClient
}