import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { resolveFileUploads } from 'libs';
import { SERVICE_TYPE, SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import AppContext from 'AppContext';
import { PERMISSIONS, ServiceType } from 'types';


interface ServiceProps { }

const Service: React.FC<ServiceProps> = () => {
  const context = React.useContext(AppContext)
  const { mainState, setAppState } = context;
  const { services, selectedServiceType } = mainState;
  const [isPos, setIpos] = React.useState<boolean>(false);


  const filteredServices = React.useMemo(() => {
    return services.filter((service) => service.type === selectedServiceType);
  }, [selectedServiceType]);

  React.useEffect(() => {
    setIpos(mainState.session.permissions.includes(PERMISSIONS.POS));
  }, [mainState.session.permissions]);

  const onServiceClickHandler = (service: ServiceType) => {
    let currentComponent;

    if (service.name.toLocaleLowerCase() === 'tufesa' &&
      service.type === SERVICE_TYPE.TICKET) {
      currentComponent = SYSTEMATIC_ROUTER_ENUM.TUFESA;
    } else if (service.name.toLocaleLowerCase() === 'token' &&
      service.type === SERVICE_TYPE.OTHER) {
      currentComponent = SYSTEMATIC_ROUTER_ENUM.TOKEN;
    } else if (service.type === SYSTEMATIC_ROUTER_ENUM.INSURANCE) {
      currentComponent = SYSTEMATIC_ROUTER_ENUM.INSURANCE_LIST;
    } else {
      currentComponent = SYSTEMATIC_ROUTER_ENUM.PAYMENT_CONTROLLER;
    }

    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent,
        selectedService: service
      },
    });
  }

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-4xl md:text-6xl font-myriad-bold text-center">
        Selecciona {selectedServiceType === SERVICE_TYPE.INSURANCE ? 'seguro' : 'proveedor'}
      </h1>
      <ScrollContainer
        className="flex flex-col mt-12"
        style={{ height: 'calc(100vh - 225px)' }}
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-12 pb-2">
          {filteredServices.map((service) => {
            if(!isPos && service.name === 'Token' && service.type === SERVICE_TYPE.OTHER){
              return;
            }

            return (
              <div
                key={service.uuid}
                onClick={() => onServiceClickHandler(service)}
                className="flex flex-col items-center justify-center h-52 border rounded-lg bg-white shadow-md"
                style={{
                  backgroundImage: `url(${resolveFileUploads(service.imageUrl)})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                {!service.imageUrl && (
                  <>
                    <span className="flex">
                      {service.name}
                    </span>
                    <span className="flex items-center justify-center">
                      {service.name}
                    </span>
                  </>
                )}
              </div>
            )
          })}
        </div>
      </ScrollContainer>
    </div>
  )
}

export default Service
