import * as React from "react";
import { toast, ToastContainer } from "react-toastify";
import ScrollContainer from "react-indiana-drag-scroll";
import AppContext, { AppContextType } from "AppContext";
import { PAYMENT_TYPE, SYSTEMATIC_ROUTER_ENUM } from "./constants";

import { NavBar, Spinner } from "components/UI";

import Scanner from "components/Scanner";
import Tufesa from "components/Tufesa";
import PaymentType from "components/PaymentType";
import Welcome from "components/Welcome";
import MenuServices from "components/MenuServices";
import Login from "components/Login";
import Service from "components/Service";
import PaymentController from "components/PaymentController";

import { tokenLoginService } from "services/AuthService";
import Print from "components/Print";
import Cut from "components/Cut";
import Insurance from "components/PaymentController/Insurance";
import InsuranceTicket from "components/PaymentController/InsuranceTicket";
import { LoginResponse, PERMISSIONS } from "types";
import Token from "components/Token";
import { deepCopy } from "libs";
import { defaultTufesaState } from "AppProvider";
import InsuranceList from "components/InsuranceList";
import { initLocation } from "libs/location";
import Cron from "libs/Cron";

import bannertest from "assets/img/bannertest.png";

import "react-toastify/dist/ReactToastify.css";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  let { currentComponent } = mainState;
  const [isPos, setIsPos] = React.useState<boolean>(false);
  const [isInit, setIsInit] = React.useState<boolean>(true);
  const [cron, setCron] = React.useState<Cron>(null);

  const login = React.useCallback(async () => {
    try {
      const { user, ...rest } = (await tokenLoginService()) as LoginResponse;
      const {
        uuid,
        username,
        rolesPermissions: { roles, permissions }
      } = user;
      const isPosT = permissions?.includes(PERMISSIONS.POS);
      setIsPos(isPosT);
      const newMainState = {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.WELCOME,
        session: {
          ...rest,
          uuid,
          username,
          roles,
          permissions,
        },
        back: onBackHandler,
      };

      if (isPosT) {
        newMainState.currentComponent = SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES;
        newMainState.paymentType = PAYMENT_TYPE.CASH;
      }

      const cronI = new Cron(rest.place.city.timeZone, () => {
        toast.warning("Actualización en curso, espere...")
        setTimeout(() => window.location.reload(), 10000);
      });
      setCron(cronI);

      setAppState({
        ...context,
        mainState: newMainState,
      }); 
      initLocation();  
      setIsInit(false);
    } catch (error) {
      setIsInit(false);
      setAppState({
        ...context,
        mainState: {
          ...mainState,
          currentComponent: SYSTEMATIC_ROUTER_ENUM.LOGIN,
          back: onBackHandler,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    login();
  }, [login]);
  
  React.useEffect(() => {
    cron?.start()

    return () => {
      cron?.stop();
    }
  }, [cron]);

  // const shouldRenderAds =
  //   currentComponent !== SYSTEMATIC_ROUTER_ENUM.WELCOME &&
  //   currentComponent !== SYSTEMATIC_ROUTER_ENUM.LOGIN &&
  //   !!currentComponent;

  const onBackHandler = (ctx?: AppContextType) => {
    setAppState({
      ...ctx,
      mainState: {
        ...ctx.mainState,
        currentComponent: renderBackRoute({ currentComponent }),
      },
      tufesaState: {
        ...deepCopy(defaultTufesaState),
        // ...ctx.tufesaState,
      },
    });
  };

  return (
    <div className={`flex flex-col h-full font-myriad-light ${isInit ? 'justify-center' : ''}`}>
      {isInit ? (<Spinner className="w-12 h-12 self-center text-red-500" />) : (
        <>
        <NavBar
        landingLayout={
          currentComponent === SYSTEMATIC_ROUTER_ENUM.WELCOME ||
          currentComponent === SYSTEMATIC_ROUTER_ENUM.PAYMENT_TYPE ||
          currentComponent === SYSTEMATIC_ROUTER_ENUM.SCAN ||
          currentComponent === SYSTEMATIC_ROUTER_ENUM.LOGIN ||
          !currentComponent
        }
        onBackHandler={onBackHandler}
      />
      <div className="w-full flex-1 bg-gray-100">
        <main className="flex w-full flex-row h-full">
          {/* {!isPos && (
            <div className="flex w-1/6 items-center justify-center">
              {shouldRenderAds && <div>[Ads]</div>}
            </div>
          )} */}
          {/* <div className="hidden sm:flex w-1/6 items-center justify-center">
            <img src={bannertest} className="h-full" />
          </div> */}
          <ScrollContainer
            className={`h-full w-full ${isPos && "mx-auto"} py-4 px-4 ${
              currentComponent === SYSTEMATIC_ROUTER_ENUM.TUFESA
                ? "bg-white"
                : ""
            }`}
            style={{ height: "calc(100vh - 90px)" }}
          >
            <div className="flex rounded-lg h-full">
              <SystematicRouter currentComponent={currentComponent} />
            </div>
          </ScrollContainer>
          {/* {!isPos && (
            <div className="flex w-1/6 items-center justify-center">
              {shouldRenderAds && <div>[Ads]</div>}
            </div>
          )} */}
          {/* <div className="hidden sm:flex w-1/6 items-center justify-center">
            <img src={bannertest} className="h-full" />
          </div> */}
        </main>
      </div>
        </>
      )}
      
      <ToastContainer autoClose={2000} />
    </div>
  );
};

// We didnt use router since we don't want to show user an url flow.. if user reloads it will erase all the current
// recolected info.
const SystematicRouter = ({
  currentComponent,
}: {
  currentComponent: SYSTEMATIC_ROUTER_ENUM;
}) => {
  let component;

  switch (currentComponent) {
    case SYSTEMATIC_ROUTER_ENUM.LOGIN:
      component = <Login />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.SCAN:
      component = <Scanner />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.WELCOME:
      component = <Welcome />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES:
      component = <MenuServices />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.SERVICE:
      component = <Service />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.PAYMENT_CONTROLLER:
      component = <PaymentController />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.PAYMENT_TYPE:
      component = <PaymentType />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.INSURANCE_LIST:
      component = <InsuranceList />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.INSURANCE:
      component = <Insurance />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.CUT:
      component = <Cut />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.TOKEN:
      component = <Token />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.PRINT:
      component = <Print />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.INSURANCE_PRINT:
      component = <InsuranceTicket />;
      break;
    case SYSTEMATIC_ROUTER_ENUM.TUFESA:
      component = <Tufesa />;
      break;
    default:
      component = <Welcome />;
      break;
  }

  return component;
};

const renderBackRoute = ({
  currentComponent,
}: {
  currentComponent: SYSTEMATIC_ROUTER_ENUM;
}) => {
  let route;

  switch (currentComponent) {
    case SYSTEMATIC_ROUTER_ENUM.SERVICE:
      route = SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES;
      break;
    case SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES:
      route = SYSTEMATIC_ROUTER_ENUM.WELCOME;
      break;
    case SYSTEMATIC_ROUTER_ENUM.INSURANCE_LIST:
    case SYSTEMATIC_ROUTER_ENUM.PAYMENT_CONTROLLER:
      route = SYSTEMATIC_ROUTER_ENUM.SERVICE;
      break;
    case SYSTEMATIC_ROUTER_ENUM.INSURANCE:
      route = SYSTEMATIC_ROUTER_ENUM.INSURANCE_LIST;
      break;
    case SYSTEMATIC_ROUTER_ENUM.TUFESA:
      route = SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES;
      break;
    default:
      route = SYSTEMATIC_ROUTER_ENUM.WELCOME;
      break;
  }

  return route;
};

export default App;
