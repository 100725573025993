import * as React from "react";
import NumberFormat from "react-number-format";

import { validateEmail } from "libs";
import Keyboard from "components/UI/Keyboard";
import { InsuranceType, PERMISSIONS, ServiceItemType } from "types";
import AppContext from "AppContext";
import { SYSTEMATIC_ROUTER_ENUM } from "../../constants";

interface InsuranceProps {}

const Insurance: React.FC<InsuranceProps> = () => {
  const keyboardRef = React.useRef<any>();
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const [state, setState] = React.useState<InsuranceType>({
    phone: "",
    email: "",
  });
  const [focusEmail, setFocusEmail] = React.useState<boolean>(false);
  const [focusPhone, setFocusPhone] = React.useState<boolean>(true);
  const [valid, setValid] = React.useState<boolean>(false);
  const [isPos, setIsPos] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValid(
      state.email !== "" &&
        state.phone !== "" &&
        state.phone.length === 10 &&
        validateEmail(state.email)
    );

    if (mainState.session?.permissions?.includes(PERMISSIONS.POS)) {
      setIsPos(true);
    }
  }, [state, mainState.session]);

  const onSubmitHandler = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.INSURANCE_PRINT,
        insurance: {
          ...mainState.insurance,
          ...state
        },
      },
    });
  };

  const onInputChange = (e: React.SyntheticEvent) => {
    const formData = e.currentTarget as HTMLInputElement;
    const { name, value } = formData;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onKChangeHandler = (value: string): void => {
    if (focusEmail) {
      setState({
        ...state,
        email: value,
      });
    } else if (focusPhone) {
      setState({
        ...state,
        phone: value,
      });
    }
  };

  return (
    <div
      className="flex flex-col w-full"
      onClick={() => {
        setFocusEmail(false);
        setFocusPhone(false);
      }}
    >
      <h1 className="text-6xl font-myriad-bold text-center capitalize">
        Información de contacto
      </h1>
      <div className="flex flex-col w-full pt-6">
        <div
          className="flex flex-col w-full"
          onClick={(e: any) => e.stopPropagation()}
        >
          <form
            className="space-y-6"
            onSubmit={async (e) => {
              e.preventDefault();
              await onSubmitHandler();
            }}
          >
            <div className="rounded-md shadow-sm mb-2">
              <div>
                <label htmlFor="phone" className="sr-only">
                  Numero telefónico
                </label>
                <div
                  onClick={() => {
                    keyboardRef?.current?.setInput(state.phone);
                    setFocusEmail(false);
                    setFocusPhone(true);
                  }}
                  className={`bg-white border rounded mb-2 border-gray-300 focus:outline-none focus:ring-kiosk-red focus:border-kiosk-red ${
                    focusPhone && "border-tufesa-main"
                  }`}
                >
                  <NumberFormat
                    disabled={!isPos}
                    name="phone"
                    format="+52 (###) ###-####"
                    mask="_"
                    value={state.phone}
                    allowEmptyFormatting={true}
                    className="w-full text-2xl p-4 bg-transparent focus:outline-none"
                    onChange={({ value }: any) =>
                      !isPos ? setState({ ...state, phone: value }) : null
                    }
                    onValueChange={({ value }: any) =>
                      isPos ? setState({ ...state, phone: value }) : null
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="sku" className="sr-only">
                Email
              </label>
              <input
                required
                id="email"
                name="email"
                type="text"
                value={state.email}
                className={`appearance-none rounded-none relative block w-full p-4 border ${
                  focusEmail ? "border-kiosk-red" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-kiosk-red focus:border-kiosk-red focus:z-10 text-2xl`}
                placeholder="Email"
                onChange={onInputChange}
                onClick={() => {
                  keyboardRef?.current?.setInput(state.email);
                  setFocusPhone(false);
                  setFocusEmail(true);
                }}
              />
            </div>
            <Keyboard keyboardRef={keyboardRef} onChange={onKChangeHandler} />

            <div>
              <button
                disabled={!valid}
                className={`group relative w-full flex justify-center p-4 border border-transparent text-xl font-medium rounded-md text-white ${
                  valid ? "bg-kiosk-red" : "bg-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                Aceptar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
