import * as React from "react";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import LoadingModal from "components/PaymentController/LoadingModal";
import MessageModal from "components/UI/Message/MessageModal";
import { useModal } from "components/UI/Modal/ModalProvider";
import {
  generateWalletTokenService,
  walletOperationService,
} from "services/WalletService";
import logo from "assets/img/logos/logo-pagocheck.png";
import { GenerateTokenResponse, ServiceType, WalletOperation, WalletOperationResponse } from "types";
import { resolveFileUploads } from "libs";
import { DateTime } from "luxon";

const TABS = ["Venta de Tokens", "Generar Código QR"];

interface TokenProps {}

type TokenState = WalletOperation;

const Token: React.FC<TokenProps> = () => {
  const componentRef = React.useRef();
  const loadingModal = useModal(LoadingModal);
  const messageModal = useModal(MessageModal);
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>(TABS[0]);
  const [qrTokenBase64, setQrTokenBase64] = React.useState<string>("");
  const [barTokenBase64, setBarTokenBase64] = React.useState<string>("");
  const [points, setPoints] = React.useState<number>(null);
  const [expires, setExpire] = React.useState<string>(null);
  const [service, setService] = React.useState<ServiceType>(null);
  const [state, setState] = React.useState<TokenState>({
    reference: "",
    points: 0,
    description: "",
  });

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (state.reference && state.points) {
      if (selectedTab === TABS[0]) {
        loadingModal({ modalButtonRef, message: "Agregando puntos al wallet" });
        walletOperationService(state)
          .then(async ({ message, ticket }: WalletOperationResponse) => {
            modalButtonRef?.current?.click();

            await messageModal({
              message,
            });

            printJS({
              type: "raw-html",
              css: resolveFileUploads('uploads/styles/tailwind.min.css'),
              printable: ticket,
            });
						clearState();
          })
          .catch(async () => {
            modalButtonRef?.current?.click();

            await messageModal({
              message: "Un error ocurrio, intentalo de nuevo!",
            });
          });
      } else if (selectedTab === TABS[1]) {
        loadingModal({ modalButtonRef, message: "Generando token..." });
        generateWalletTokenService({
          reference: state.reference,
          points: state.points
        })
          .then(async ({ qrToken, barToken, expiresAt, points, service }: GenerateTokenResponse) => {
            modalButtonRef?.current?.click();

            setService(service);
            setQrTokenBase64(qrToken);
            setBarTokenBase64(barToken);
            setExpire(expiresAt);
            setPoints(points);
          })
          .catch(async () => {
            modalButtonRef?.current?.click();

            await messageModal({
              message: "Un error ocurrio, intentalo de nuevo!",
            });
          });
      }
    }
  };

  const onChange = (e: React.SyntheticEvent) => {
    const { name, value } = e.currentTarget as HTMLFormElement;
    let newValue = value;

    if (name === "points") {
      newValue = parseFloat(value);
    }

    setState({
      ...state,
      [name]: newValue,
    });
  };

  const clearState = () => {
    setState({
      reference: "",
      points: 0,
      description: "",
    });
		setBarTokenBase64(null);
		setQrTokenBase64(null);
  };

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-5xl font-myriad-bold text-center">
        Generación de PagoCheck Tokens
      </h1>
      <div className="flex flex-col w-full justify-center mt-12">
        <div className="flex flex-col w-4/6 mx-auto">
          <div
            className={`border-b border-red-600 grid grid-cols-${TABS.length} gap-2`}
          >
            {TABS.map((tab) => (
              <div
                key={tab}
                className={`text-center p-4 cursor-pointer ${
                  tab === selectedTab ? "bg-red-500 text-white" : ""
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>

          {selectedTab === TABS[0] && (
            <form className="w-full mx-auto mt-8 space-y-6" onSubmit={onSubmit}>
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="reference" className="sr-only">
                    Número de referencia
                  </label>
                  <input
                    id="reference"
                    name="reference"
                    type="text"
                    value={state.reference}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm"
                    placeholder="Número de referencia"
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label htmlFor="points" className="sr-only">
                    Cantidad de tokens
                  </label>
                  <input
                    id="points"
                    name="points"
                    type="number"
                    value={state.points || ""}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm"
                    placeholder="Tokens a agregar"
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label htmlFor="description" className="sr-only">
                    Descripción (opcional)
                  </label>
                  <input
                    id="description"
                    name="description"
                    type="texto"
                    value={state.description}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm"
                    placeholder="Descripción"
                    onChange={onChange}
                  />
                </div>
              </div>

              <div>
                <button
                  disabled={!(state.points && state.reference)}
                  className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
                    state.points && state.reference
                      ? "bg-kiosk-red"
                      : "bg-gray-600 cursor-not-allowed"
                  } text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
                >
                  Aceptar
                </button>
              </div>
            </form>
          )}

          {selectedTab === TABS[1] && (
            <div className="flex flex-col mt-2 p-4">

              {qrTokenBase64 && barTokenBase64 && (
                <div className="h-64 overflow-y-auto">
                  <div
                    ref={componentRef}
                    className="flex flex-col m-2 p-2 border-4 border-dashed text-center border-black rounded"
                    style={{ minHeight: 200 }}
                  >
                    <img className="w-96 mx-auto" src={logo} />
                    <img className="w-1/2 mt-6 mx-auto" src={qrTokenBase64} />
                    <img className="w-full mt-6" src={barTokenBase64} />
                    <p className="font-bold text-2xl mt-4">PagoCheck Token</p>
                    <p className="text-xl mt-6">
                      Valido por <b className="text-2xl">{points}</b> punto(s)
                    </p>
                    <p className="text-xl">{`Vigencia al ${DateTime.fromISO(
                      expires
                    ).toFormat("dd/MM/yyyy t")}`}</p>
                    <p className="text-base">
                      Token valido para un solo uso, los puntos son acumulables
                    </p>
                    <div
                      className="mt-2 text-justify"
                      dangerouslySetInnerHTML={{
                        __html: service.settings.printDisclaimer,
                      }}
                    />
                  </div>
                </div>
              )}

              <form
                className="w-full mx-auto mt-2 space-y-2"
                onSubmit={onSubmit}
              >
                <input type="hidden" name="remember" value="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="reference" className="sr-only">
                      Número Telefónico
                    </label>
                    <input
                      id="reference"
                      name="reference"
                      type="text"
                      value={state.reference}
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm"
                      placeholder="Número de referencia"
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="points" className="sr-only">
                      Cantidad de Tokens
                    </label>
                    <input
                      id="points"
                      name="points"
                      type="number"
                      value={state.points || ""}
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-700 focus:border-red-700 focus:z-10 sm:text-sm"
                      placeholder="Cantidad de Tokens"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <button
                    disabled={!(state.points && state.reference)}
                    className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
                      state.points && state.reference
                        ? "bg-kiosk-red"
                        : "bg-gray-600 cursor-not-allowed"
                    } text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
                  >
                    Generar
                  </button>
                </div>
              </form>
              {qrTokenBase64 && barTokenBase64 && (
                <ReactToPrint
                  onAfterPrint={() => clearState()}
                  trigger={() => (
                    <button className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                      Imprimir
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Token;
