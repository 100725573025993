import React from "react";
import { loginService } from "services/AuthService";
import { initLocation } from "libs/location";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [cardAppId, setCardAppId] = React.useState<string>("");
  const [latitude, setLatitude] = React.useState<number>(null);
  const [longitude, setLongitude] = React.useState<number>(null);
  const [deviceCode, setDeviceCode] = React.useState<string>("");
  const [showLocation, setShowLocation] = React.useState<boolean>(false);

  const onUsernameChanged = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setUsername(target.value);
  };

  const onPasswordChanged = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setPassword(target.value);
  };

  const onDeviceCode = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setDeviceCode(target.value);
  };

  const onCardAppIdChange = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setCardAppId(target.value);
  };
  
  const onLatitudeChange = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setLatitude(parseFloat(target.value));
  };
  
  const onLongitudeChange = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;

    setLongitude(parseFloat(target.value));
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    localStorage.setItem("appId", cardAppId);
    
    if(latitude && longitude) {
      localStorage.setItem("useStorage", "1");
      localStorage.setItem("location", JSON.stringify(`${longitude},${latitude}`));
    }

    loginService(username, password, deviceCode).then(
      () => window.location.reload()
    );

    e.preventDefault();
  };

  React.useEffect(() => {
    localStorage.setItem("appId", null);
    initLocation();  
    const show = !localStorage.getItem('location');
    setShowLocation(show)
  }, [showLocation]);

  return (
    <div className="flex w-full justify-center mt-32">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Iniciar sesión
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={onSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Usuario
              </label>
              <input
                id="username"
                name="username"
                type="texto"
                value={username}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Usuario"
                onChange={onUsernameChanged}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Contraseña
              </label>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Contraseña"
                onChange={onPasswordChanged}
              />
              <label htmlFor="username" className="sr-only">
                Código de dispositivo
              </label>
              <input
                id="deviceCode"
                name="deviceCode"
                type="texto"
                value={deviceCode}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Código de dispositivo"
                onChange={onDeviceCode}
              />
              <label htmlFor="cardAppId" className="sr-only">
                Codigo App Pinpad
              </label>
              <input
                id="cardAppId"
                name="cardAppId"
                type="texto"
                value={cardAppId}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Codigo APP Pinpad (requerido para pago con tarjeta)"
                onChange={onCardAppIdChange}
              />
              {showLocation && (
                <>
                  <label htmlFor="latitude" className="sr-only">
                    Latitud
                  </label>
                  <input
                    id="latitude"
                    name="latitude"
                    type="texto"
                    value={latitude ?? ''}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                    placeholder="Latitud"
                    onChange={onLatitudeChange}
                  />
                  <label htmlFor="longitude" className="sr-only">
                    Longitud
                  </label>
                  <input
                    id="longitude"
                    name="longitude"
                    type="texto"
                    value={longitude ?? ''}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                    placeholder="Longitud"
                    onChange={onLongitudeChange}
                  />
                </>
              )}
            </div>
          </div>

          <div>
            <button className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-kiosk-red focus:outline-none focus:ring-2 focus:ring-offset-2">
              Aceptar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
