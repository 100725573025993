
import { Option } from "react-select/src/filters";
import { PageViewType, TicketCategoryType, SeatType } from "types";

export enum STEP_VIEW_ITEMS {
  HORARIOS = 0,
  ASIENTOS = 1,
  PAGO = 2
};

export enum PAGE_VIEW_ITEMS {
  ORIGEN_DESTINO = 0,
  FECHA = 1,
  ASIENTOS = 2,
  RESUMEN = 3,
  PAGO = 4
};

export enum TICKET_CATEGORY_TYPE_ENUM {
  C = "C",
  I = "I",
  M = "M",
  E = "E",
  P = "P",
};

export enum TICKET_CATEGORY_TYPE_LABEL_ENUM {
  C = "Adulto",
  I = "INAPAM",
  M = "Niño",
  E = "Estudiante",
  P = "Maestro",
};

export enum TICKET_TYPE_ICON_ENUM {
  C = "https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos web-54.png",
  I = "https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos web-55.png",
  E = "https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos web-56.png",
  M = "https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos web-57.png",
  P = "https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos web-59.png"
};

// component details
export const PAGE_VIEW_LIST: PageViewType[] = [
  {
    name: 'Seleccionar origen y destino',
    position: PAGE_VIEW_ITEMS.ORIGEN_DESTINO
  },
  {
    name: 'Seleccionar fecha',
    position: PAGE_VIEW_ITEMS.FECHA
  },
  {
    name: 'Seleccione asientos',
    position: PAGE_VIEW_ITEMS.ASIENTOS
  },
  {
    name: 'Resumen',
    position: PAGE_VIEW_ITEMS.RESUMEN
  },
  {
    name: 'Pago',
    position: PAGE_VIEW_ITEMS.PAGO
  }
];

// its for showing current step.
export const STEP_VIEW_LIST: PageViewType[] = [
  {
    name: 'Horarios',
    position: STEP_VIEW_ITEMS.HORARIOS
  },
  {
    name: 'Asientos',
    position: STEP_VIEW_ITEMS.ASIENTOS
  },
  {
    name: 'Pago',
    position: STEP_VIEW_ITEMS.PAGO
  }
];

export const TICKETS: TicketCategoryType[] = [
  {
    type: TICKET_CATEGORY_TYPE_ENUM.C,
    title: TICKET_CATEGORY_TYPE_LABEL_ENUM.C,
    details: 'Tarifa regular',
    quantity: 1
  },
  {
    type: TICKET_CATEGORY_TYPE_ENUM.I,
    title: TICKET_CATEGORY_TYPE_LABEL_ENUM.I,
    details: 'Adultos mayores',
    quantity: 0
  },
  {
    type: TICKET_CATEGORY_TYPE_ENUM.M,
    title: TICKET_CATEGORY_TYPE_LABEL_ENUM.M,
    details: 'De 3 a 11 años',
    quantity: 0
  },
  {
    type: TICKET_CATEGORY_TYPE_ENUM.E,
    title: TICKET_CATEGORY_TYPE_LABEL_ENUM.E,
    details: 'Con credencial',
    quantity: 0
  },
  {
    type: TICKET_CATEGORY_TYPE_ENUM.P,
    title: TICKET_CATEGORY_TYPE_LABEL_ENUM.P,
    details: 'Personal docente',
    quantity: 0
  },
];

export enum SCHEDULE_FILTER_VALUES {
  HOUR = 'hour',
  CHEAP = 'cheap',
  EXPENSIVE = 'expensive',
}

export const SCHEDULE_FILTERS: Option[] = [
  {
    label: 'Hr. Llegada',
    value: SCHEDULE_FILTER_VALUES.HOUR,
    data: null
  },
  {
    label: 'Precio menor',
    value: SCHEDULE_FILTER_VALUES.CHEAP,
    data: null
  },
  {
    label: 'Precio mayor',
    value: SCHEDULE_FILTER_VALUES.EXPENSIVE,
    data: null
  }
];

export enum BUS_SERVICE_ICON_SET {
  TITANIUM = "https://ventatufesa.azurewebsites.net/v3/titanium.115078294ff102611a2e.png",
  PLUS = "https://ventatufesa.azurewebsites.net/v3/plus.9715c9ea782103b66b00.png"
};
