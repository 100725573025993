import * as React from "react";

import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal, Spinner } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalFooter from "components/UI/Modal/ModalFooter";
import ModalHeader from "components/UI/Modal/ModalHeader";
import Pinpad from "libs/pinpad";
import NumberFormat from "react-number-format";
import { PERMISSIONS } from "types";
import AppContext from "AppContext";
import printJS from "print-js";

interface CardPaymentModalProps {
  amount: string;
  reference: string;
  username: string;
  serviceName: string;
}

const CardPaymentModal: React.FC<CardPaymentModalProps & ModalProps> = ({
  username,
  reference,
  amount,
  serviceName,
  onSubmit,
  onReject,
}) => {
  const context = React.useContext(AppContext);
  const { mainState } = context;
  const { session } = mainState;
  const { permissions } = session;
  const [logginIn, setLogginIn] = React.useState<boolean>(false);
  const [pinpadLoggedIn, setPinpadLoggedIn] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reading, setReading] = React.useState<boolean>(false);
  const [doingPayment, setDoingPayment] = React.useState<boolean>(false);
  const [maskPan, setMaskPan] = React.useState<string>("");
  const [canPay, setCanPay] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  const [result, setResult] = React.useState<any>(null);
  const [pinpadInstance, setPinpadInstance] = React.useState<Pinpad>(null);
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const [rTimeout, setRTimeout] = React.useState<any>(null);

  React.useEffect(() => {
    if (!pinpadInstance && session) {
      const user = session.params.find(
        (param) => param.key === "pinpad_user"
      ).value;
      const password = session.params.find(
        (param) => param.key === "pinpad_password"
      ).value;
      const environment = session.params.find(
        (param) => param.key === "pinpad_env"
      ).value;
      setPinpadInstance(
        new Pinpad({
          user,
          password,
          environment,
        })
      );
    }

    if (pinpadLoggedIn) {
      startTimeout();
      startCardReading();
    } else if (!pinpadLoggedIn && pinpadInstance) {
      setLoading(true);
      setLogginIn(true);
      startTimeout();
      const data = pinpadInstance.loginPinpad();

      if(data?.error){
        setError({
          type: "error",
          message: "Ocurrió un error al leer la PinPad",
        });
        setLoginError(true);
        setLoading(false);
        setLogginIn(false);
      }

      pinpadInstance.handleMessage((data) => {
        if (data.type === "login") {
          setLogginIn(false);
          setLoading(false);
          setPinpadLoggedIn(data.success);

          if (data.error) {
            setError({
              type: "error",
              message: data.error,
            });
            setLoginError(true);
          }
        }

        if (data.type === "read") {
          setLoading(false);
          setReading(false);
          setCanPay(data.success);
          setMaskPan(data.maskPan);
          stopTimeout();

          if (data.error) {
            setError({
              type: "error",
              message: data.error,
            });
          }
        }

        if (data.type === "readCancel") {
          setLoading(false);
          setCanPay(false);
          setMaskPan("");
          onReject();
          stopTimeout();
        }

        if (data.type === "pay" || data.type === "timeout") {
          setLoading(false);
          setCanPay(false);
          setDoingPayment(false);
          stopTimeout();

          if (data.success) {
            setResult(data);
            printJS({
              type: "raw-html",
              printable: pinpadInstance.escapeVoucher(data.voucher_cliente),
            });
          } else {
            setError({
              type: data.response,
              message: data.error || data.nb_error,
            });
          }
        }
      });
    }

    return () => {
      stopTimeout();
    }
  }, [pinpadLoggedIn, pinpadInstance, session]);

  const startCardReading = () => {
    setLoading(true);
    setReading(true);
    setError("");
    pinpadInstance.readCard({
      amount,
    });
  };

  const confirmPayment = () => {
    setLoading(true);

    pinpadInstance.payCard({
      reference,
      pinpadUser: username,
      operationType: "11",
    });
  };

  const cancelRead = () => {
    setLoading(true);
    pinpadInstance.readCardCancel();
  };

  const finish = () => {
    onSubmit({
      message: "success",
      data: result,
    });
  };

  const startTimeout = () => {
    setRTimeout(setTimeout(() => {
      setError({
        type: "error",
        message: "Ocurrió un error al leer la PinPad",
      });
      setLoginError(true);
      setLoading(false);
      setLogginIn(false);
    }, 60000));
  }
  
  const stopTimeout = () => {
    if(rTimeout){
      clearTimeout(rTimeout);
    }
  }

  return (
    <Modal>
      <ModalHeader showCloseButon={false}>Pago con tarjeta</ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full h-64 items-center justify-center">
          {!canPay && (
            <div className="flex flex-col w-full text-center items-center justify-center">
              {logginIn && <h1 className="text-xl">Preparando PinPad</h1>}
              {reading && <h1 className="text-xl">Desliza Tarjeta</h1>}
              {doingPayment && <h1 className="text-xl">Realizando Pago</h1>}
            </div>
          )}

          {canPay && !doingPayment && !loading && (
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Tarjeta</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {maskPan}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Cantidad
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Servicio
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {serviceName}
                  </dd>
                </div>
              </dl>
            </div>
          )}

          {loading && (
            <Spinner className="h-16 w-16 text-kiosk-red mt-4 mx-auto" />
          )}

          {error?.type === "error" && (
            <div className="flex flex-col w-full bg-red-200 p-4">
              <p className="text-base text-red-600">
                Ocurió un error al realizar el cobro.
              </p>
              <p className="text-base text-red-600">
                "No se realizó ningún cargo a su tarjeta"
              </p>
              <p className="text-base text-red-600">
                Description: {error.message}
              </p>
            </div>
          )}
          {error?.type === "denied" && (
            <div className="flex flex-col w-full bg-yellow-200 p-4">
              <p className="text-base text-yellow-600">
                "No se realizó ningún cargo a su tarjeta"
              </p>
              <p className="text-base text-yellow-600">
                La operacion fue rechazada por su banco emisor. \n Favor de
                intenter con otra tarjeta
              </p>
            </div>
          )}
          {result && (
            <div className="flex flex-col w-full bg-green-200 p-4">
              <p className="text-base text-green-600 font-bold">
                "COBRO APROBADO"
              </p>
              <p className="text-base text-green-600">
                Referencia: {result.reference || result.nb_referencia}
              </p>
              <p className="text-base text-green-600">
                Monto: {result.amount || result.nu_importe}
              </p>
              <p className="text-base text-green-600">
                Número de Operación: {result.tp_operation || result.nu_operaion}
              </p>
              <p className="text-base text-green-600">
                Número de Autorización: {result.auth || result.nu_auth}
              </p>
              <p className="text-base text-green-600">
                Tipo de Tarjeta: {result.cc_type || result.cc_tp}
              </p>
              <p className="text-base text-green-600">
                Fecha de Operación:{" "}
                {result.fh_registro || `${result.date} ${result.time}`}
              </p>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {canPay && !doingPayment && !loading && !result && (
          <>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={confirmPayment}
            >
              Confirmar Pago
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onReject({ message: "cancel" })}
            >
              Cancelar
            </button>
          </>
        )}

        {error && (
          <>
            {!loginError && (
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={startCardReading}
              >
                Reintentar
              </button>
            )}
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => onReject({ message: "cancel" })}
            >
              Cancelar
            </button>
          </>
        )}

        {reading && (
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={cancelRead}
          >
            Cancelar
          </button>
        )}

        {result && (
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={finish}
          >
            Siguiente
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CardPaymentModal;
