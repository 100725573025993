import { DateTime } from "luxon";
import PinPadEvents from "./listener";

interface PinPadType {
  environment: string;
  user: string;
  password: string;
}

interface Sell {
  Country: string;
  IdBranch: string;
  IdCompany: string;
  pwd: string;
  User: string;
  Currency: string;
  Merchant: string;
  Reference: string;
  UserTRX: string;
  TpOperation: string;
  EMV: string;
  ModeloTerminal: string;
  SerieTerminal: string;
  Contactless: string;
  Printer: string;
  VersionTerminal: string;
  Ambiente: string;
  Reverse: string;
}

class PinPad extends PinPadEvents implements PinPadType {
  readonly appVersion = "Versión 1.0.13";
  private afiliaciones: any = {
    cont: null,
    msi: null,
    plazosmci: null,
  };
  private id: string;
  private timeout: any;
  environment: string;
  user: string;
  password: string;
  currencies: string;
  private sell: Sell = {
    Country: null,
    IdBranch: null,
    IdCompany: null,
    pwd: null,
    User: null,
    Currency: null,
    Merchant: null,
    Reference: null,
    UserTRX: null,
    TpOperation: null,
    EMV: null,
    ModeloTerminal: null,
    SerieTerminal: null,
    Contactless: null,
    Printer: null,
    VersionTerminal: null,
    Ambiente: null,
    Reverse: null,
  };

  constructor({
    environment,
    user,
    password,
  }: {
    environment: string;
    user: string;
    password: string;
  }) {
    super();
    this.id = localStorage.getItem("appId");
    this.environment = environment;
    this.user = user;
    this.password = password;
  }

  private clean = () => {
    this.sell = {
      Country: null,
      IdBranch: null,
      IdCompany: null,
      pwd: null,
      User: null,
      Currency: null,
      Merchant: null,
      Reference: null,
      UserTRX: null,
      TpOperation: null,
      EMV: null,
      ModeloTerminal: null,
      SerieTerminal: null,
      Contactless: null,
      Printer: null,
      VersionTerminal: null,
      Ambiente: null,
      Reverse: null,
    };
  };

  private startTimeout = () => {
    this.timeout = setTimeout(this.timeoutConsult, 60000);
  };

  private clearTimeout = () => {
    clearTimeout(this.timeout);
  };

  private getVoucher = async ({ nu_operaion }: any) => {
    const rePrintPayload = {
      Ambiente: this.sell.Ambiente,
      User: this.user,
      Pwd: this.password,
      IdBranch: this.sell.IdBranch,
      IdCompany: this.sell.IdCompany,
      Country: this.sell.Country,
      Tx_OperationNumber: nu_operaion,
    };

    return await new Promise((resolve, _) => {
      const portL = chrome.runtime.connect(this.id, { name: "sndReimpresion" });
      portL.postMessage(rePrintPayload);
      portL.onMessage.addListener((response) => {
        resolve(JSON.parse(response));
      });
    });
  };

  private timeoutConsult = () => {
    this.clearTimeout();

    const payload = {
      Ambiente: this.sell.Ambiente,
      User: this.sell.User,
      Pwd: this.sell.pwd,
      IdBranch: this.sell.IdBranch,
      IdCompany: this.sell.IdCompany,
      Country: this.sell.Country,
      Tx_Date: DateTime.now().toFormat("dd/MM/yyyy"),
      Reference: this.sell.Reference,
    };

    let data: any = {};
    const portSC = chrome.runtime.connect(this.id, { name: "sndConsulta" });
    portSC.postMessage(payload);

    portSC.onMessage.addListener(async (response) => {
      const { transaccion, Respuesta, ERROR, codError } = JSON.parse(response);

      if (!transaccion && Respuesta) {
        data = {
          ...transaccion,
          type: "timeout",
          success: false,
          response: ERROR,
          error: `${codError}:${ERROR}`,
        };
      } else if (transaccion.nb_response === "error") {
        data = {
          ...transaccion,
          type: "timeout",
          success: false,
        };
      } else if (transaccion.nb_response === "denied") {
        data = {
          ...transaccion,
          type: "timeout",
          success: false,
        };
      } else {
        const voucherData = (await this.getVoucher(transaccion)) as any;

        data = {
          ...voucherData,
          type: "timeout",
          success: true,
        };
      }

      this.sendMessage(data);
      this.clean();
    });
  };

  // Utilities
  // 0 by default (MXN)
  public getCurrency = () => {
    let currencies: any = [];
    const currencyTypeString = this.currencies;

    if (currencyTypeString !== null && currencyTypeString !== "") {
      const currenciesString = `${currencyTypeString}|`;

      if (currenciesString.includes(",")) {
        currencies = currenciesString.split("|");
        currencies = currencies.map((currency: any) => {
          currency = currency.split(",");
          return {
            text: currency[2],
            code: currency[1],
            currency: currency[0],
          };
        });
      }
    }

    return currencies.length ? currencies[0] : null;
  };

  // Save pinpad data.
  public loginPinpad = () => {
    if (!this.id) {
      return {
        error: "Pinpad plugin id not set!"
      }
    }

    if (chrome?.runtime?.connect(this.id)) {
      console.log(`Pinpad version: ${this.appVersion}`);

      let portL = chrome.runtime.connect(this.id, { name: "login" });

      portL.postMessage({
        Ambiente: this.environment,
        Usuario: this.user,
        Pass: this.password,
      });

      portL.onMessage.addListener((response) => {
        // console.log("respWP" + response);
        let data;

        let obj = JSON.parse(response);
        let aux = obj.RESPUESTA;

        if (aux === null || aux === "error") {
          data = {
            type: "login",
            success: false,
            error: obj.ERROR,
          };
          this.sendMessage(data);
          return;
        } else {
          localStorage.setItem("datosusuario", response);
          localStorage.setItem("user", `${this.user}|${this.password}`);
          this.currencies = obj.monedas;

          const datosUser = localStorage.getItem("datosusuario");
          const objUser = JSON.parse(datosUser);
          const objDataKeys: any = {};

          objDataKeys.Ambiente = this.environment;
          objDataKeys.Usuario = this.user;
          objDataKeys.Pass = this.password;
          objDataKeys.Country = objUser.bs_country;
          objDataKeys.IdBranch = objUser.bs_branch;
          objDataKeys.IdCompany = objUser.bs_company;

          //Se llaman las llaves RSA
          // console.log("getKeyRSA");
          portL = chrome.runtime.connect(this.id, { name: "getKeysRSA" });
          portL.postMessage(objDataKeys);

          portL.onMessage.addListener((response) => {
            // console.log("respWP" + response);
            localStorage.setItem("datosPinPad", response);

            obj = JSON.parse(response);
            aux = obj.RESPUESTA;

            if (aux === null || aux === "error") {
              console.error("Error!", obj.ERROR);
              data = {
                type: "login",
                success: false,
              };
            } else {
              // console.log("Bien!", "Login correcto...");
              data = {
                type: "login",
                success: true,
              };
            }

            this.sendMessage(data);
          });
        }
      });
    }
  };

  // Read card
  public readCard = ({
    amount,
    timeout = "60",
  }: {
    amount: string;
    timeout?: string;
  }) => {
    const selectedCurrency = this.getCurrency();

    amount = `${parseFloat(`${amount}`).toFixed(2)}`;

    if (!selectedCurrency) {
      console.error("Cannot obtain merchant");

      return;
    }

    const datosPinpadString = localStorage.getItem("datosPinPad");
    const datosPinpad = JSON.parse(datosPinpadString);

    const objDataTRX = {
      Ambiente: this.environment,
      Currency: selectedCurrency.currency,
      CurrencyCode: selectedCurrency.code,
      Amount: amount,
      MarcaTerminal: datosPinpad.marca,
      ModeloTerminal: datosPinpad.modelo,
      TimeOutPinPad: timeout,
      SoportaCTLS: datosPinpad.soportaCTLS,
    };
    // Get card merchant
    const portRC = chrome.runtime.connect(this.id, { name: "readCard" });
    portRC.postMessage(objDataTRX);

    portRC.onMessage.addListener((response) => {
      // console.log("respWP-" + response);
      let aux = "";
      let objTRX = JSON.parse(response);

      aux = objTRX.RESPUESTA;
      let data: any = {
        maskPan: objTRX.maskPan,
      };

      if (aux === null || aux === "error") {
        console.error("Error ", `${objTRX.codError}:${objTRX.ERROR}`);
        this.sendMessage({
          type: "read",
          success: false,
          error: `${objTRX.codError}:${objTRX.ERROR}`,
        });
        return;
      } else {
        let binT = "";
        if (objTRX.maskPan.length > 6) binT = objTRX.maskPan.substring(0, 6);

        const objDataMerchant = {
          Ambiente: this.environment,
          BIN: binT, //BIN
          User: this.user, //User
          Currency: objDataTRX.Currency, //Currency
        };

        //Get Afiliaciones
        const portGM = chrome.runtime.connect(this.id, {
          name: "getMerchant",
        });
        portGM.postMessage(objDataMerchant);
        portGM.onMessage.addListener((response2) => {
          // console.log(`respWP getMerchant- ${response2}`);
          objTRX = JSON.parse(response2);
          aux = objTRX.respuesta;

          if (aux === null || aux === "0") {
            const respMer = objTRX.nb_respuesta;

            if (respMer === "" || respMer === null || respMer === undefined) {
              console.error("Error: ", objTRX.ERROR);

              data = {
                ...data,
                type: "read",
                success: false,
                error: `${objTRX.codError}:${objTRX.ERROR}`,
              };
              this.sendMessage(data);
              return;
            }
          } else {
            //se muestran las afiliaciones
            this.afiliaciones = {
              cont: objTRX?.contado?.af,
              msi: objTRX?.msi?.af,
              plazosmci: objTRX?.plazosmci?.af,
            };

            data = {
              ...data,
              type: "read",
              success: true,
            };
          }
          this.sendMessage(data);
        });
      }
    });
  };

  //  Cancel card read (Is not working)
  public readCardCancel = () => {
    const pinpadDataString = localStorage.getItem("datosPinPad");
    const objPinpad = JSON.parse(pinpadDataString);
    let portCR = chrome.runtime.connect(this.id, { name: "cancelReadCard" });
    portCR.postMessage(objPinpad.marca);

    portCR.onMessage.addListener((response) => {
      // console.log("respWP-" + response);
      let data = JSON.parse(response);

      data = {
        ...data,
        type: "readCancel",
      };

      this.sendMessage(data);
    });
  };

  public payCard = ({
    reference,
    pinpadUser,
    // 11 POS
    // 29 KIOSK
    operationType = "11",
  }: {
    reference: string;
    pinpadUser: string;
    operationType?: string;
  }) => {
    const selectedCurrency = this.getCurrency();
    const userDataString = localStorage.getItem("datosusuario");
    const objUser = JSON.parse(userDataString);
    const pinpadDataString = localStorage.getItem("datosPinPad");
    const objPinpad = JSON.parse(pinpadDataString);

    this.sell = {
      Country: objUser.bs_country,
      IdBranch: objUser.bs_branch,
      IdCompany: objUser.bs_company,
      pwd: this.password,
      User: this.user,
      Currency: selectedCurrency.currency,
      Merchant: this.afiliaciones.cont.merchant,
      Reference: reference,
      UserTRX: pinpadUser,
      TpOperation: operationType,
      EMV: objPinpad.EMV,
      ModeloTerminal: objPinpad.modelo,
      SerieTerminal: objPinpad.serie,
      Contactless: objPinpad.soportaCTLS,
      Printer: objPinpad.impresora,
      VersionTerminal: objPinpad.versionApp,
      Ambiente: this.environment,
      Reverse: objUser.ExecuteReverse,
    };

    this.startTimeout();
    const portSDEMV = chrome.runtime.connect(this.id, {
      name: "sndVentaDirectaEMV",
    });
    portSDEMV.postMessage(this.sell);
    portSDEMV.onMessage.addListener((response) => {
      let data: any = {};
      const objResponse = JSON.parse(response);
      const aux = objResponse.RESPUESTA;

      if (aux === null || aux === "error") {
        console.error("Error ", `${objResponse.codError}:${objResponse.ERROR}`);
        this.sendMessage({
          type: "pay",
          success: false,
          error: `${objResponse.codError}:${objResponse.ERROR}`,
        });
        return;
      }

      if (!objResponse) {
        data = {
          ...objResponse,
          response: "error",
          type: "pay",
          success: false,
        };
      } else if (objResponse.response === "error") {
        data = {
          ...objResponse,
          type: "pay",
          success: false,
        };
      } else if (objResponse.response === "denied") {
        data = {
          ...objResponse,
          type: "pay",
          success: false,
        };
      } else {
        data = {
          ...objResponse,
          voucher_cliente: objResponse.voucher_cliente,
          voucher_comercio: objResponse.voucher_comercio,
          type: "pay",
          success: true,
        };
      }
      this.sendMessage(data);
      this.clean();
      this.clearTimeout();
    });
  };

  // Utility.
  public escapeVoucher = (voucher: string) => {
    voucher = voucher
      .replace("@cnb logo_cpagos", "")
      .replace("@cnn ver_app", "")
      .replace(/@cnb /g, "")
      .replace(/@cnn /g, "")
      .replace(/@br /g, "")
      .replace(/\n/g, "<br>")
      .replace(/@lnn /g, "")
      .replace(/@lsn /g, "")
      .replace(/@bc /g, "");

    return voucher;
  };
}

export default PinPad;
