import * as React from 'react';
import scanImage from 'assets/img/icon-scan.png';
import AppContext from 'AppContext';
import { SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import { getTokenDataService } from 'services/WalletService';
import { TokenType } from 'types';

export interface ScannerProps {
}

const Scanner: React.FC<ScannerProps> = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const [value, setValue] = React.useState<string>('');
  const [currentTimeOut, setCurrentTimeOut] = React.useState<ReturnType<typeof setTimeout>>(null);

  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const onFocusLost = () => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }

  const fetchTokenInfo = async (v: string) => {
    setValue('');

    const token = await getTokenDataService(v.length <= 24 ? { id: v } : { tokenUUID: v }) as TokenType;

    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES,
        account: {
          token
        }
      }
    });
  }

  const onChangeHanlder = (e: React.SyntheticEvent) => {
    const form = e.currentTarget as HTMLInputElement;
    const { value } = form;

    setValue(value);

    clearTimeout(currentTimeOut);

    const timeOut = setTimeout(() => fetchTokenInfo(value), 500);

    setCurrentTimeOut(timeOut);
  }

  const onBackHandler = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.WELCOME,
      }
    });
  }

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col text-center">
        <h1 className="text-7xl mb-4 font-myriad-bold">Escanear</h1>
        <h5 className="text-xl mb-12 capitalize">Escanea tu token para continuar</h5>
        <img
          className="h-52 mx-auto"
          src={scanImage}
          alt="escanear token"
        />
        <input
          autoFocus
          ref={inputRef}
          value={value}
          onChange={onChangeHanlder}
          onBlur={onFocusLost}
          style={{ height: 0 }}
        />
        <button 
          className="mt-8 w-full flex justify-center p-2 border border-transparent text-xl font-medium rounded-md text-white bg-kiosk-red focus:outline-none focus:ring-2 focus:ring-offset-2"
          onClick={onBackHandler}
        >
          Atrás
        </button>
      </div>
    </div>
  );
}

export default Scanner;