import * as React from "react";
import NumberFormat from "react-number-format";
import AppContext from "AppContext";
import { SYSTEMATIC_ROUTER_ENUM } from "../../constants";
import { resolveFileUploads } from "libs";
import ScrollContainer from "react-indiana-drag-scroll";
import { ChubbData, ChubbOption, ServiceItemType } from "types";
import Row from "./Row";

interface InsuranceListProps {}

const InsuranceList: React.FC<InsuranceListProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const { selectedService } = mainState;
  const [selectedChubb, setSelectedChubb] = React.useState<ChubbData>();

  const onItemSelect = (item: ServiceItemType) => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        selectedServiceItem: item,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.INSURANCE,
      },
    });
  };

  const onChubbItemSelect = (chubbOption: ChubbOption) => {
    const price = chubbOption.resources + chubbOption.netPremium;

    // We map this to render ticket without issues.
    const item: ServiceItemType = {
      uuid: `${selectedChubb.packageId}`,
      name: selectedChubb.packageName,
      item: {
        uuid: `${selectedChubb.packageId}`,
        name: `Cobertura por ${chubbOption.days} dia(s)`,
        sku: `${chubbOption.days}`,
        details: selectedChubb.description,
        imageUrl: selectedService.imageUrl,
      },
      price,
      details: selectedChubb.description,
    };

    setAppState({
      ...context,
      mainState: {
        ...mainState,
        selectedServiceItem: item,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.INSURANCE,
        insurance: {
          ...mainState.insurance,
          // Chubb insurance object preparation,
          chubb: {
            packageId: selectedChubb.packageId,
            days: chubbOption.days,
          },
        },
      },
    });
  };

  const serviceItemMapper = React.useMemo(() => {
    return selectedService.items.map((item) => {
      return {
        id: item.uuid,
        name: item.item.name,
        details: item.item.details,
        item,
        price: item.price,
        imageUrl: item.item.imageUrl,
      };
    });
  }, [selectedService]);

  const externalServiceItemMapper = React.useMemo(() => {
    return (
      selectedService.metadata?.data?.map((chubb) => {
        return {
          id: chubb.packageId,
          name: chubb.packageName,
          details: chubb.description,
          item: chubb,
          price: 0,
          imageUrl: selectedService.imageUrl,
        };
      }) ?? []
    );
  }, [selectedService]);

  const externalServiceOptionsItemMapper = React.useMemo(() => {
    return (
      selectedChubb?.options?.map((option) => {
        const price = option.resources + option.netPremium;

        return {
          id: `option-${selectedChubb.packageId}`,
          name: `Cobertura por ${option.days} dia(s)`,
          details: selectedChubb.description,
          item: option,
          price,
          imageUrl: selectedService.imageUrl,
        };
      }) ?? []
    );
  }, [selectedChubb, selectedService]);

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-6xl font-myriad-bold text-center capitalize">
        Selecciona tu {!selectedChubb ? "paquete" : "cobertura"}
      </h1>
      <ScrollContainer
        className="flex flex-col mt-12"
        style={{ height: "calc(100vh - 230px)" }}
      >
        <ul>
          {serviceItemMapper.map((item) => (
            <Row
              id={item.id}
              name={item.name}
              details={item.details}
              imageUrl={item.imageUrl}
              price={item.price}
              onItemSelect={onItemSelect}
              item={item.item}
            />
          ))}

          {!selectedChubb &&
            externalServiceItemMapper.map((item) => (
              <Row
                id={item.id}
                name={item.name}
                details={item.details}
                priceText="Seleccionar Paquete"
                imageUrl={item.imageUrl}
                price={item.price}
                onItemSelect={(item: ChubbData) => setSelectedChubb(item)}
                item={item.item}
              />
            ))}

          {selectedChubb &&
            externalServiceOptionsItemMapper.map((item) => (
              <Row
                id={item.id}
                name={item.name}
                details={item.details}
                imageUrl={item.imageUrl}
                price={item.price}
                priceText={`${item.price} USD`}
                onItemSelect={(item: ChubbOption) => onChubbItemSelect(item)}
                item={item.item}
              />
            ))}
        </ul>
      </ScrollContainer>
    </div>
  );
};

export default InsuranceList;
