import * as React from "react";
import { DateTime } from "luxon";
import { deepCopy } from "libs";
import { PERMISSIONS } from "types";

import { AppContext, AppContextType, TufesaAppStateType } from "AppContext";
import {
  PAGE_VIEW_LIST,
  STEP_VIEW_LIST,
  TICKETS,
  PAGE_VIEW_ITEMS,
  STEP_VIEW_ITEMS,
} from "./constants";
import ModalProvider from "components/UI/Modal/ModalProvider";
import App from "App";
import ErrorBundary from "ErrorBundary";
import LockScreen from "components/LockScreen";

export const defaultTufesaState: TufesaAppStateType = {
  origin: null,
  destination: null,
  departureDate: DateTime.now().toISODate(),
  returnDate: null,
  choosingDepartureSeats: false,
  choosingReturnSeats: false,
  departureSchedule: null,
  returnTicket: null,
  tickets: TICKETS,
  email: null,
  phone: null,
  marketing: false,
  pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.ORIGEN_DESTINO],
  stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.HORARIOS],
};

const AppProvider = () => {
  // App main state
  const [appState, setAppState] = React.useState<AppContextType>({
    mainState: {
      currentComponent: null,
      locked: !!localStorage.getItem("lockPassword"),
    },
    tufesaState: deepCopy(defaultTufesaState),
  });

  const value = {
    ...appState,
    setAppState,
  };

  React.useEffect(() => {
    const {
      mainState: { session },
    } = appState;

    if (
      session?.permissions?.includes(PERMISSIONS.KIOSK) &&
      process.env.MODE !== "development"
    ) {
      require("assets/style/noncursor.scss");
    }
  }, [appState.mainState.session]);

  const onLockChange = (locked: boolean) => {
    setAppState((prev) => ({
      ...prev,
      mainState: {
        ...prev.mainState,
        locked,
      },
    }));
  };

  return (
    <ErrorBundary>
      <AppContext.Provider value={value}>
        <ModalProvider>
          <div className="relative h-full">
            {appState.mainState.locked ? (
              <LockScreen onLockChange={onLockChange} />
            ) : (
              <App />
            )}
            <div className="text-xs text-left absolute px-2 py-1 bottom-0 text-gray-400">
              Version: {process.env.appVersion}
            </div>
          </div>
        </ModalProvider>
      </AppContext.Provider>
    </ErrorBundary>
  );
};

export default AppProvider;
