import AppContext from "AppContext";
import MessageModal from "components/UI/Message/MessageModal";
import { useModal } from "components/UI/Modal/ModalProvider";
import { PAYMENT_TYPE, SYSTEMATIC_ROUTER_ENUM } from "../../constants";
import { DateTime } from "luxon";
import * as React from "react";
import { createExternalServiceOrderService } from "services/OrderService";
import LoadingModal from "./LoadingModal";
import CardPaymentModal from "components/CardPaymentModal";
import { generateRandomString } from "libs";

interface InsuranceTicketProps {}

const InsuranceTicket: React.FC<InsuranceTicketProps> = () => {
  const cardPaymentModal = useModal(CardPaymentModal);
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const loadingModal = useModal(LoadingModal);
  const messageModal = useModal(MessageModal);
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const {
    selectedService,
    selectedServiceItem,
    account,
    insurance,
    paymentType,
    session,
  } = mainState;
  const {  place: { settings } } = session;
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [comissionTotal, setComissionTotal] = React.useState<number>(0);
  const [grandTotal, setGrandTotal] = React.useState<number>(0);
  const overrideComissionSettings = settings?.overrideComission;

  React.useEffect(() => {
    let price = selectedServiceItem.price;
    const comission = overrideComissionSettings ?
      settings.comission +
      price * settings.comissionPercent :
      selectedService.settings.comission +
      price * selectedService.settings.comissionPercent;

    let grandComission = comission

    if (paymentType === PAYMENT_TYPE.CARD) {
      grandComission = comission + price;
      const cardComission = session.params.find(
        (param) => param.key === "card_comission"
      );

      grandComission = grandComission * parseFloat(cardComission?.value || "0");
      grandComission += comission;
    }

    setComissionTotal(grandComission);
    setGrandTotal(price + grandComission);
  }, [comissionTotal, selectedServiceItem, paymentType, session]);

  const onPayClickHandler = async () => {
    let payloadData: any = {
      ...insurance,
      code: `${selectedService.name.substring(0, 1)}-${generateRandomString(
        6
      )}-${DateTime.now().toFormat("ddMMyy")}`.toLocaleUpperCase(),
    };

    if (paymentType === PAYMENT_TYPE.CARD) {
      const result = await cardPaymentModal({
        username: session.username,
        reference: payloadData.code.replace(/-/g, ""),
        amount: grandTotal,
        serviceName: selectedService.name,
      });

      if (result.message === "success") {
        payloadData = {
          ...payloadData,
          extraData: result.data,
        };
      } else {
        return;
      }
    }

    const createData = {
      paymentType: paymentType,
      tokenUUID: account?.token?.uuid,
      serviceUUID: selectedService.uuid,
      serviceItemUUID: selectedServiceItem.uuid,
      amount: grandTotal,
      payload: payloadData,
    };

    loadingModal({ modalButtonRef, message: "Solicitando información" });
    createExternalServiceOrderService(createData)
      .then((payloadString: any) => {
        setRequesting(false);
        modalButtonRef.current.click();

        setAppState({
          mainState: {
            ...mainState,
            currentComponent: SYSTEMATIC_ROUTER_ENUM.PRINT,
            externalServiceOrder: JSON.parse(payloadString),
          },
        });
      })
      .catch(({ message }) => {
        modalButtonRef?.current?.click();
        messageModal({
          message,
        });
      });
  };

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-5xl font-myriad-bold text-center">
        Detalles del seguro
      </h1>
      <div className="flex flex-col w-full mt-12 p-4 bg-white h-full rounded text-xl">
        <div className="border-b border-gray-300 pt-6">
          <span className="font-bold">Compañia:</span>
          <span className="ml-6 text-gray-600">{selectedService.name}</span>
        </div>
        <div className="border-b border-gray-300 pt-6">
          <span className="font-bold">Servicio:</span>
          <span className="ml-6 text-gray-600">
            {selectedServiceItem.item.name}
          </span>
        </div>
        <div className="border-b border-gray-300 pt-6">
          <span className="font-bold">Fecha:</span>
          <span className="ml-6 text-gray-600">
            {DateTime.now().toFormat("dd/MM/yyyy HH:mm")}
          </span>
        </div>
        <div className="flex flex-col border-b border-gray-300 pt-6">
          <span className="font-bold">Detalles:</span>
          <span className="ml-6 text-gray-600">
            {selectedServiceItem.item.details}
          </span>
        </div>
        <div className="flex flex-col border-b border-gray-300 pt-6">
          <span className="font-bold">Comisón y cuota de servicio:</span>
          <span className="ml-6 text-gray-600">{`$${comissionTotal}`}</span>
        </div>
        <div className="border-b border-gray-300 pt-6">
          <span className="font-bold">Precio:</span>
          <span className="ml-6 text-gray-600">{`$${selectedServiceItem.price}`}</span>
        </div>
        <div className="border-b border-gray-300 pt-6">
          <span className="font-bold">Total:</span>
          <span className="ml-6 text-gray-600">{`$${grandTotal}`}</span>
        </div>
      </div>
      <div>
        <button
          className="group relative w-full flex justify-center p-4 border border-transparent text-xl font-medium rounded-md text-white bg-kiosk-red focus:outline-none focus:ring-2 focus:ring-offset-2"
          onClick={() => (!requesting ? onPayClickHandler() : null)}
        >
          Pagar
        </button>
      </div>
    </div>
  );
};

export default InsuranceTicket;
