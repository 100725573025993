import * as React from 'react';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';

export interface MessageModalProps {
  message?: string
}

const MessageModal: React.FC<MessageModalProps & ModalProps> = ({ onSubmit, message }) => {


  return (
    <Modal onDismiss={() => onSubmit({ data: 'success' })} >
      <ModalHeader>
        Mensaje
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            {message}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-tufesa-dark hover:bg-tufesa-main text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => onSubmit({ data: 'success' })}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default MessageModal;
