import * as React from 'react';
import printJS from 'print-js';
import AppContext from 'AppContext';
import { SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import { deepCopy, resolveFileUploads } from 'libs';
import { defaultTufesaState } from 'AppProvider';

interface PrintProps {

}

const Print: React.FC<PrintProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const { externalServiceOrder } = mainState;
  const html = `
    <div class="flex flex-col">
      ${externalServiceOrder.ticketHtml}
      <div class="flex py-4 px-6 text-lg">
        Comisión del servicio:${" "}
        <span class="font-bold ml-auto">
          $
          ${externalServiceOrder.items.reduce(
            (acc, val) => acc + val.comission,
            0
          )}
        </span>
      </div>
    </div>`;

    console.log(resolveFileUploads('uploads/styles/tailwind.min.css'))

  React.useEffect(() => {
    printJS({
      type: "raw-html",
      css: resolveFileUploads('uploads/styles/tailwind.min.css'),
      printable: html,
      scanStyles: false
    });
  }, [externalServiceOrder]);

  const onFinishHandler = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.WELCOME,
        selectedService: null,
        selectedServiceItem: null,
        selectedServiceType: null
      },
      tufesaState: deepCopy(defaultTufesaState)
    });
  }

  return (
    <div className="w-full flex flex-row items-center">
      <div className="flex flex-col mx-auto">
        <h3 className="text-4xl text-center">Compra Finalizada</h3>
        <button
          className="w-32 mx-auto p-4 bg-kiosk-red text-white rounded-full text-xl mt-4"
          onClick={onFinishHandler}
        >
          Terminar
        </button>
      </div>
    </div>
  );
}

export default Print;