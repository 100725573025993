import * as React from "react";
import printJS from "print-js";
import { DateTime } from "luxon";
import LoadingModal from "components/PaymentController/LoadingModal";
import MessageModal from "components/UI/Message/MessageModal";
import { useModal } from "components/UI/Modal/ModalProvider";
import { cutReportService } from "services/ReportService";
import { CutOrder, CutReport } from "types";
import { resolveFileUploads } from "libs";
import { logoutService } from "services/AuthService";
import { cutOrdersService } from "services/OrderService";
import NumberFormat from "react-number-format";
import AppContext from "AppContext";

interface CutProps {}

const Cut: React.FC<CutProps> = () => {
  const modalButtonRef = React.useRef<HTMLButtonElement>(null);
  const loadingModal = useModal(LoadingModal);
  const messageModal = useModal(MessageModal);
  const [cutOrder, setCutOrder] = React.useState<CutOrder>(null);

  const fetchCutOrder = React.useCallback(async () => {
    const cutOrderI = await cutOrdersService() as any;

    setCutOrder(cutOrderI);
  }, [])

  React.useEffect(() => {
    fetchCutOrder();
  }, [fetchCutOrder])

  const onGenerateClickHanlder = async () => {
    loadingModal({ modalButtonRef, message: "Realizando soliticud" });
    cutReportService()
      .then(async ({ message, ticketHtml }: CutReport) => {
        modalButtonRef?.current?.click();
        await messageModal({ message });

        printJS({
          type: "raw-html",
          css: resolveFileUploads("uploads/styles/tailwind.min.css"),
          printable: ticketHtml,
          onPrintDialogClose: () => {
            window.location.reload();
            // logoutService().then(() => {
            //   window.location.reload();
            // });
          },
        });
      })
      .catch(async ({ message }) => {
        modalButtonRef?.current?.click();
        await messageModal({ message });
      });
  };

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-5xl font-myriad-bold text-center">Corte de Caja</h1>
      <div className="flex flex-col w-full justify-center">
        <div className="flex flex-col mx-auto py-4">
          <h2 className="text-3xl font-bold py-2">Precorte</h2>
          <div
            className="flex flex-col overflow-y-auto"
            style={{ height: "calc(100vh - 410px)" }}
          >
            <div className="inline-block py-2 m-w-full overflow-x-auto">
              <table className="table-auto">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Servicio
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Transacciones
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Monto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cutOrder?.orders?.map((order) => {
                    return (
                      <tr
                        key={order.serviceName}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {order.serviceName}
                        </td>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {order.totalServiceOrders}
                        </td>
                        <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                          <NumberFormat
                            value={order.serviceTotalWithoutComission.toFixed(
                              2
                            )}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row space-x-4 justify-end text-xl">
            <span>Comisiones: </span>
              <NumberFormat
                value={cutOrder?.grandTotalComission?.toFixed(2) ?? 0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
          </div>
          <div className="flex flex-row space-x-4 justify-end text-xl">
            <span>Total: </span>
            <NumberFormat
              value={
                cutOrder?.grandTotal?.toFixed(2) ?? 0
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </div>
        </div>
        <button
          className="w-1/3 mt-2 mx-auto rounded p-4 border bg-kiosk-red text-white"
          onClick={onGenerateClickHanlder}
        >
          Generar
        </button>
      </div>
    </div>
  );
};

export default Cut;
