import * as React from "react";
import { resolveFileUploads } from "libs";
import NumberFormat from "react-number-format";
import { Chubb, ChubbData, ChubbOption, ServiceItemType } from "types";

interface RowProps {
  id: number | string;
  imageUrl: string;
  name: string;
  details: string;
  price: number;
  priceText?: string;
  item: ServiceItemType | ChubbData | ChubbOption;
  onItemSelect: (item: ServiceItemType | ChubbData | ChubbOption) => void;
}

const Row: React.FC<RowProps> = ({
  id,
  imageUrl,
  name,
  details,
  price,
  priceText,
  item,
  onItemSelect,
}) => {
  return (
    <div
      key={id}
      className="flex border-gray-400 rounded m-2 p-4 h-42 bg-gray-200 mb-4"
      onClick={() => onItemSelect(item)}
    >
      <div className="relative flex flex-col w-1/3 h-full">
        <img
          className="w-full h-40 rounded"
          src={resolveFileUploads(imageUrl)}
        />
        <p className="w-full absolute rounded-b bottom-0 bg-red-600 text-white text-2xl text-center py-2 opacity-90">
          {priceText ?? (
            <NumberFormat
              value={price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          )}
        </p>
      </div>
      <div className="flex flex-col w-2/3 pl-4">
        <h3 className="text-3xl">{name}</h3>
        <p className="text-2xl text-gray-600">{details}</p>
      </div>
    </div>
  );
};

export default Row;
