import * as React from 'react';
import { STEP_VIEW_LIST, PAGE_VIEW_LIST, TICKET_CATEGORY_TYPE_LABEL_ENUM } from '../../../constants';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import AppContext from 'AppContext';
import { CustomerType, SeatType } from 'types';
import Keyboard from 'components/UI/Keyboard';


export type SeatAssigmentModalProps = ModalProps & {
  seat?: SeatType
}

const SeatAssigmentModal: React.FC<SeatAssigmentModalProps & ModalProps> = ({ onDismiss, onReject, onSubmit, seat }) => {
  const keyboardRef = React.useRef<any>();
  const context = React.useContext(AppContext);
  const { tufesaState, setAppState } = context;
  const [focusName, setFocusName] = React.useState<boolean>(false);
  const [focusLastname, setFocusLastname] = React.useState<boolean>(false);
  const [state, setState] = React.useState<CustomerType>({
    name: '',
    lastname: '',
    category: seat._category,
    seat: seat._id
  });

  const onChangeHandler = (el: React.SyntheticEvent) => {
    const input = el.currentTarget as HTMLInputElement;
    const { name, value } = input;

    setState({
      ...state,
      [name]: value
    });
  }

  const onContinueHandler = () => {
    onSubmit({ data: state, message: 'success' });
  }

  const onCancelHandler = () => {
    setAppState({
      ...context,
      tufesaState: {
        ...tufesaState,
        marketing: false,
        email: null,
        phone: null
      }
    });

    onReject();
  }

  const onChangeInput = (input: string): void => {
    if (focusName) {
      setState({
        ...state,
        name: input,
      });
    } else if (focusLastname) {
      setState({
        ...state,
        lastname: input,
      });
    }

    keyboardRef.current.setInput(input);
  };

  return (
    <Modal
      variant="lg"
      onDismiss={onDismiss}
    >
      <ModalHeader>
        Pasajero
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <div className="text-gray-500 text-center py-1">Tipo pasajero: <b>{TICKET_CATEGORY_TYPE_LABEL_ENUM[seat?._category]}</b></div>
            <div className="text-gray-500 text-center mb-8">Asiento: <b>{seat?._id}</b></div>
            <input
              name="name"
              value={state?.name}
              className="p-4 border rounded mb-2 border-gray-400 focus:outline-none focus:border-tufesa-main"
              placeholder="Nombre"
              onChange={onChangeHandler}
              onFocus={() => {
                keyboardRef?.current?.setInput(state.name);
                setFocusLastname(false);
                setFocusName(true);
              }}
            />
            <input
              name="lastname"
              value={state?.lastname}
              className="p-4 border rounded mb-2 border-gray-400 focus:outline-none focus:border-tufesa-main"
              placeholder="Apellido"
              onChange={onChangeHandler}
              onFocus={() => {
                keyboardRef?.current?.setInput(state.lastname);
                setFocusName(false);
                setFocusLastname(true);
              }}
            />
            <Keyboard keyboardRef={keyboardRef} onChange={onChangeInput} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${state?.name && state?.lastname ? 'bg-tufesa-dark hover:bg-tufesa-main' : 'bg-gray-300'} text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={onContinueHandler}
        >
          Continuar
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onCancelHandler}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default SeatAssigmentModal;
