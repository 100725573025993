import { apolloClient } from "libs/apolloClient";
import { gql } from "@apollo/client";
import {
  ACTION_TYPE,
  GenerateToken,
  TokenFetchType,
  WalletOperation,
} from "types";
import ErrorHandler from "libs/ApolloErrorHandler";

const getTokenDataService = (tokenDetails: TokenFetchType) => {
  return apolloClient()
    .query({
      query: gql`
        query fetchTokenData($input: FetchTokenDataInput!) {
          fetchTokenData(input: $input) {
            id
            uuid
            points
          }
        }
      `,
      variables: {
        ...tokenDetails,
      },
    })
    .then(res => ErrorHandler(res))
    .then(({ data }): Object => {
      const tokenData = data.fetchTokenData;

      return tokenData;
    });
};

const walletOperationService = (walletData: WalletOperation) => {
  return apolloClient()
    .mutate({
      mutation: gql`
        mutation walletOperation($input: WalletOperationInput!) {
          walletOperation(input: $input) {
            message
            ticket
          }
        }
      `,
      variables: {
        input: {
          ...walletData,
          action: ACTION_TYPE.ADD,
        },
      },
    })
    .then(res => ErrorHandler(res))
    .then(({ data }): Object => {
      const walletData = data.walletOperation;

      return walletData;
    });
};

const generateWalletTokenService = (walletData: GenerateToken) => {
  return apolloClient()
    .mutate({
      mutation: gql`
        mutation generateWalletToken($input: GenerateWalletTokenInput!) {
          generateWalletToken(input: $input) {
            points
            qrToken
            barToken
            expiresAt
            service {
              settings {
                printDisclaimer
              }
            }
          }
        }
      `,
      variables: {
        input: {
          ...walletData,
        },
      },
    })
    .then(res => ErrorHandler(res))
    .then(({ data }): Object => {
      const walletData = data.generateWalletToken;

      return walletData;
    });
};

export {
  getTokenDataService,
  walletOperationService,
  generateWalletTokenService,
};
