import * as React from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import axios, { AxiosResponse } from "axios";
import Select from "react-select";
import { DateScheduleType, FilterOptionType, ScheduleType } from "types";
import { generateDates, readeableDate } from "libs";
import { Spinner } from "components/UI";
import ScheduleDetailRow from "./ScheduleDetailRow";
import {
  SCHEDULE_FILTERS,
  PAGE_VIEW_LIST,
  STEP_VIEW_LIST,
  STEP_VIEW_ITEMS,
  PAGE_VIEW_ITEMS,
  SCHEDULE_FILTER_VALUES,
} from "../../../constants";
import AppContext from "AppContext";
import { useModal } from "components/UI/Modal/ModalProvider";
import MessageModal from "./MessageModal";

export interface ScheduleStepProps {}

const ScheduleStep: React.FC<ScheduleStepProps> = () => {
  const context = React.useContext(AppContext);
  const { tufesaState, mainState, setAppState } = context;
  const { session } = mainState;
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    departureSchedule,
    returnSchedule,
    tickets,
  } = tufesaState;
  const [dateSchedules, setDateSchedules] = React.useState<DateScheduleType[]>(
    []
  );
  const messageModal = useModal(MessageModal);
  const [dates, setDates] = React.useState<string[]>([]);
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [selectedDateSchedule, setSelectedDateSchedule] =
    React.useState<DateScheduleType>(null);
  const [enableScheduleCarousel] = React.useState<boolean>(false);
  const [filterBy, setFilterBy] = React.useState<FilterOptionType>(null);
  const [apiUrl, setApiUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (!apiUrl) {
      const api_url = session.params.find(
        (param) => param.key === "tufesa_api_url"
      ).value;
      setApiUrl(api_url);
    } else {
      const isDeparture = departureDate && !departureSchedule;
      const isReturn = returnDate && departureSchedule && !returnSchedule;
      const axiosDatesPromise: Promise<AxiosResponse>[] = [];
      let aUrl: string;
      let aPromis;

      setRequesting(true);
      if (enableScheduleCarousel) {
        if (isDeparture && dates.length === 0) {
          setDates(
            generateDates({
              days: 4,
              startIsoDate: departureDate?.replaceAll("-", ""),
            })
          );
        } else if (isReturn && !isDeparture && dates.length === 0) {
          setDates(
            generateDates({
              days: 4,
              startIsoDate: returnDate?.replaceAll("-", ""),
            })
          );
        }

        dates.forEach((d) => {
          if (isDeparture) {
            aUrl = `${apiUrl}/schedules?from=${origin.idField}&to=${
              destination.idField
            }&date=${d.replaceAll("-", "")}`;
          } else if (isReturn) {
            aUrl = `${apiUrl}/schedules?from=${destination.idField}&to=${
              origin.idField
            }&date=${d.replaceAll("-", "")}`;
          }

          aPromis = axios.get(aUrl);
          axiosDatesPromise.push(aPromis);
        });
      } else {
        if (isDeparture) {
          aUrl = `${apiUrl}/schedules?from=${origin.idField}&to=${
            destination.idField
          }&date=${departureDate.replaceAll("-", "")}`;
        } else if (isReturn) {
          aUrl = `${apiUrl}/schedules?from=${destination.idField}&to=${
            origin.idField
          }&date=${returnDate.replaceAll("-", "")}`;
        }

        aPromis = axios.get(aUrl);
        axiosDatesPromise.push(aPromis);
      }

      Promise.all(axiosDatesPromise)
        .then((res) => {
          let allDateSchedules: DateScheduleType[] = [];

          res.forEach(({ data }: any, index) => {
            const { dataField } = data._Response;
            const dateSchdls = dataField
              ? ({
                  date: dates[index],
                  schedules: dataField[0]._schedules as ScheduleType[],
                } as DateScheduleType)
              : ({
                  date: dates[index],
                  schedules: [] as ScheduleType[],
                } as DateScheduleType);

            allDateSchedules = allDateSchedules.concat(dateSchdls);
          });

          setDateSchedules(allDateSchedules);
          setSelectedDateSchedule(allDateSchedules[0]);
          setRequesting(false);
        })
        .catch((err) => console.log(err));
    }
  }, [
    setDateSchedules,
    setRequesting,
    dates,
    departureDate,
    returnDate,
    departureSchedule,
    returnSchedule,
    session,
    apiUrl,
  ]);

  const cheapCost = (schedules: ScheduleType[]) => {
    const cheap = schedules.reduce((a, s) => {
      const ccheap = s._category.reduce((acc, cat) => {
        return acc === 0 || acc < cat._value ? cat._value : acc;
      }, 0);

      return a === 0 || a < ccheap ? ccheap : a;
    }, 0);

    return cheap;
  };

  const loadDates = ({ more }: { more: boolean }) => {
    setDates(
      generateDates({
        days: 4,
        future: more,
        startIsoDate: more ? dates[dates.length - 1] : dates[0],
      })
    );
  };

  const onSelectSchedule = async (schedule: ScheduleType, notAvaibleTicketMessage: string) => {

    if(notAvaibleTicketMessage){
      await messageModal({ message: notAvaibleTicketMessage });

      return
    }
    
    const state = {
      ...context,
      tufesaState: {
        ...tufesaState,
      },
    };

    if (departureDate && !departureSchedule) {
      state.tufesaState = {
        ...state.tufesaState,
        departureSchedule: schedule,
      };
    } else if (
      departureDate &&
      returnDate &&
      departureSchedule &&
      !returnSchedule
    ) {
      state.tufesaState = {
        ...state.tufesaState,
        returnSchedule: schedule,
      };
    }

    if (
      (departureDate &&
        !returnDate &&
        state.tufesaState.departureSchedule &&
        !state.tufesaState.returnSchedule) ||
      (departureDate &&
        returnDate &&
        state.tufesaState.departureSchedule &&
        state.tufesaState.returnSchedule)
    ) {
      state.tufesaState.stepView = STEP_VIEW_LIST[STEP_VIEW_ITEMS.ASIENTOS];
      state.tufesaState.pageView = PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.ASIENTOS];
      state.tufesaState.choosingDepartureSeats = true;
    }

    setDates([]);
    setAppState(state);
  };

  const onFilterChange = (option: FilterOptionType) => {
    setFilterBy(option);
    let filteredSchedules;

    switch (option.value) {
      case SCHEDULE_FILTER_VALUES.CHEAP:
        filteredSchedules = selectedDateSchedule.schedules.sort((a, b) => {
          const na = tickets.reduce((acc, ticket) => {
            return (
              acc +
              a._category.find((cat) => cat._id === ticket.type)?._value *
                ticket.quantity
            );
          }, 0);

          const nb = tickets.reduce((acc, ticket) => {
            return (
              acc +
              b._category.find((cat) => cat._id === ticket.type)?._value *
                ticket.quantity
            );
          }, 0);

          return na - nb;
        });
        break;
      case SCHEDULE_FILTER_VALUES.EXPENSIVE:
        filteredSchedules = selectedDateSchedule.schedules.sort((a, b) => {
          const na = tickets.reduce((acc, ticket) => {
            return (
              acc +
              a._category.find((cat) => cat._id === ticket.type)?._value *
                ticket.quantity
            );
          }, 0);

          const nb = tickets.reduce((acc, ticket) => {
            return (
              acc +
              b._category.find((cat) => cat._id === ticket.type)?._value *
                ticket.quantity
            );
          }, 0);

          return nb - na;
        });
        break;
      case SCHEDULE_FILTER_VALUES.HOUR:
        filteredSchedules = selectedDateSchedule.schedules.sort((a, b) => {
          return (
            parseFloat(a._arrival_time.replaceAll(":", ".")) -
            parseFloat(b._arrival_time.replaceAll(":", "."))
          );
        });
        break;

      default:
        break;
    }

    const updatedSelecteDateSchedule = {
      ...selectedDateSchedule,
      schedules: filteredSchedules,
    };

    setSelectedDateSchedule(updatedSelecteDateSchedule);
  };

  const hasSchedules =
    dateSchedules.length > 0 && selectedDateSchedule?.schedules?.length > 0;

  return (
    <div className="flex flex-col w-full flex-1">
      {/* Carousel */}
      {enableScheduleCarousel && (
        <div className="flex w-full flex-row relative">
          <div
            className="flex cursor-pointer absolute left-0 -mt-6 inset-y-1/2 bg-primary-dark hover:bg-primary-light text-white rounded-full h-12 w-12 justify-center items-center text-md"
            onClick={() => loadDates({ more: false })}
          >
            {`<`}
          </div>
          {dateSchedules.map((dateSchedule, index) => {
            return (
              <div
                onClick={() => setSelectedDateSchedule(dateSchedule)}
                key={`${index}-${dateSchedule?.schedules[0]?._id}`}
                className="w-1/5 p-1"
              >
                <div
                  className={`flex sm:p-2 p-8 rounded border cursor-pointer items-center justify-center ${
                    dateSchedule.date === selectedDateSchedule?.date
                      ? "border-primary-light"
                      : "border-gray-300"
                  }`}
                  style={{ height: 110 }}
                >
                  {requesting ? (
                    <Spinner className="h-6 w-6 text-tufesa-main" />
                  ) : (
                    <div className="flex flex-col">
                      <span className="text-center text-xs text-gray-600 text-base">
                        {readeableDate(dateSchedule.date)}{" "}
                        {dateSchedule?.schedules[0]?._category && `desde`}
                      </span>
                      <span
                        className={`text-center text-lg ${
                          dateSchedule?.schedules[0]?._category &&
                          "text-tufesa-main"
                        }`}
                      >
                        {dateSchedule.schedules?.length > 0
                          ? `$${cheapCost(dateSchedule.schedules)} MXN`
                          : "No Disponible"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div
            className="flex cursor-pointer absolute right-0 -mt-6 inset-y-1/2 bg-primary-dark hover:bg-primary-light text-white rounded-full h-12 w-12 justify-center items-center text-md"
            onClick={() => loadDates({ more: true })}
          >
            {`>`}
          </div>
        </div>
      )}

      {/* Details */}
      <div className="w-full flex-1  flex-col">
        {/* Filter */}
        <div className="flex flex-col mb-4">
          <label className="text-sm text-gray-600 text-center mt-2">
            *El precio esta basado en la tarifa de adulto y solo aplica para la
            misma
          </label>
          <div className="flex w-2/6 mx-auto">
            <label className="flex items-center text-gray-600 text-base w-2/6">
              Ordernar por:
            </label>
            <Select
              isDisabled={!hasSchedules}
              placeholder="Filtros"
              options={SCHEDULE_FILTERS}
              className={`w-4/6 border ${
                hasSchedules
                  ? "border-primary-dark focus:border-primary-light"
                  : "border-gray-200 bg-gray-100"
              } rounded-full p-2`}
              value={filterBy}
              styles={{
                control: (styles) => ({
                  ...styles,
                  border: 0,
                  color: "#fe5a1f",
                  boxShadow: "none",
                  height: "90%",
                  minHeight: 25,
                }),
                valueContainer: (styles) => ({
                  ...styles,
                  height: "100%",
                  minHeight: 20,
                  padding: 0,
                }),
                singleValue: (styles, _) => ({ ...styles, color: "#fe5a1f" }),
              }}
              onChange={onFilterChange}
            />
          </div>
        </div>

        <div className="flex flex-col w-full">
          <span className="ml-auto p-2">
            <svg
              height="20"
              width="20"
              className="text-tufesa-main"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="currentColor"
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
              />
            </svg>
          </span>
        </div>

        <ScrollContainer
          className="flex flex-col w-full"
          style={{
            height: enableScheduleCarousel
              ? "calc(100vh - 565px"
              : "calc(100vh - 435px",
          }}
        >
          {/* Detail row */}
          {hasSchedules && !requesting ? (
            selectedDateSchedule?.schedules.map((schedule, index) => {
              return (
                <ScheduleDetailRow
                  key={`${index}-${schedule._id}`}
                  schedule={schedule}
                  tickets={tickets}
                  onSelect={onSelectSchedule}
                />
              );
            })
          ) : requesting ? (
            <Spinner className="w-12 h-12 text-tufesa-main" />
          ) : (
            <div className="w-full h-full text-center p-4 text-gray-500">
              Sin Corridas Dispoinles
            </div>
          )}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default ScheduleStep;
