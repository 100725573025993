import * as React from 'react';
import printJS from 'print-js';
import { DateTime } from 'luxon';

import AppContext from 'AppContext';
import { PAGE_VIEW_LIST, TICKETS, STEP_VIEW_LIST, PAGE_VIEW_ITEMS, STEP_VIEW_ITEMS, SYSTEMATIC_ROUTER_ENUM } from '../../../constants';
import { resolveFileUploads } from 'libs';

export interface PrintTicketProps {
  ticketHtml?: string
}

const PrintTicket: React.FC<PrintTicketProps> = ({ ticketHtml }) => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;

  React.useEffect(() => {
    if(ticketHtml){
      printJS({
        type: "raw-html",
        css: 'https://public-pagocheck.s3.us-east-2.amazonaws.com/tailwind.min.css',
        printable: ticketHtml,
      });
    }
  }, [ticketHtml]);

  const onFinishHandler = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.WELCOME,
      },
      tufesaState: {
        origin: null,
        destination: null,
        departureDate: DateTime.now().toISODate(),
        returnDate: null,
        choosingDepartureSeats: false,
        choosingReturnSeats: false,
        departureSchedule: null,
        returnTicket: null,
        tickets: TICKETS,
        email: null,
        phone: null,
        marketing: false,
        pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.ORIGEN_DESTINO],
        stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.HORARIOS]
      }
    });
  }

  return (
    <div className="w-full flex flex-row items-center">
      <div className="flex flex-col mx-auto mt-52">
        <h3 className="text-4xl text-center">Compra Finalizada</h3>
        <button
          className="w-32 mx-auto p-4 bg-tufesa-main text-white rounded-full text-xl mt-4"
          onClick={onFinishHandler}
        >
          Terminar
        </button>
      </div>
    </div>
  )
}


export default PrintTicket;