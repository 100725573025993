import * as React from "react";
import axios from "axios";
import ScrollContainer from 'react-indiana-drag-scroll';
import AppContext from "AppContext";
import {
  TICKET_TYPE_ICON_ENUM,
  TICKET_CATEGORY_TYPE_ENUM,
  STEP_VIEW_ITEMS,
  PAGE_VIEW_ITEMS,
  STEP_VIEW_LIST,
  PAGE_VIEW_LIST,
} from "../../../constants";
import {
  AboardSeatType,
  SeatRowType,
  SeatType,
  TicketCategoryType,
  TicketType,
} from "types";
import SeatAssigmentModal from "./SeatAssigmentModal";
import { useModal } from "components/UI/Modal/ModalProvider";
import { deepCopy, generateRandomIntegerInRange } from "libs";
import MessageModal from "./MessageModal";
import TicketCategoryModal from "./TicketCategoryModal";

interface SeatsProps {}

const Seats: React.FC<SeatsProps> = () => {
  const context = React.useContext(AppContext);
  const { tufesaState, mainState, setAppState } = context;
  const { session } = mainState;
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    tickets,
    choosingDepartureSeats,
    choosingReturnSeats,
    departureSchedule,
    returnSchedule,
  } = tufesaState;
  const [localTickets, setlocalTickets] = React.useState<TicketCategoryType[]>(
    deepCopy(tickets)
  );
  const [currentTicket, setCurrentTicket] =
    React.useState<TicketCategoryType>(null);
  const [aboardSeats, setAboardTickets] = React.useState<AboardSeatType[]>([]);
  const [seatRows, setSeatRows] = React.useState<SeatRowType[]>([]);
  const seatAssigmentModal = useModal((props) => (
    <SeatAssigmentModal {...props} />
  ));
  const messageModal = useModal((props) => <MessageModal {...props} />);
  const ticketCategoryModal = useModal((props) => (
    <TicketCategoryModal {...props} />
  ));
  const [apiUrl, setApiUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (!apiUrl) {
      const api_url = session.params.find(
        (param) => param.key === "tufesa_api_url"
      ).value;
      setApiUrl(api_url);
    } else {
      // Get the first one
      const cTicket = localTickets.filter((ticket) => ticket.quantity > 0)[0];
      setCurrentTicket(cTicket);
      let url;

      if (choosingDepartureSeats && !choosingReturnSeats) {
        url = `${apiUrl}/seats?from=${origin.idField}&to=${
          destination.idField
        }&date=${departureDate.replaceAll("-", "")}&schedule=${
          departureSchedule._id
        }`;
      } else if (!choosingDepartureSeats && choosingReturnSeats) {
        url = `${apiUrl}/seats?from=${destination.idField}&to=${
          origin.idField
        }&date=${returnDate.replaceAll("-", "")}&schedule=${
          returnSchedule._id
        }`;
      }

      if (seatRows.length === 0) {
        axios
          .get(url)
          .then(({ data }: any) => {
            const sRows = data?._Response?.dataField[0]?._row || [];

            setSeatRows(sRows);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [localTickets, seatRows, setSeatRows, setCurrentTicket, session, apiUrl]);

  const getIcon = (type: string) => {
    let icon;

    switch (type) {
      case TICKET_CATEGORY_TYPE_ENUM.C:
        icon = TICKET_CATEGORY_TYPE_ENUM.C;
        break;
      case TICKET_CATEGORY_TYPE_ENUM.E:
        icon = TICKET_CATEGORY_TYPE_ENUM.E;
        break;
      case TICKET_CATEGORY_TYPE_ENUM.I:
        icon = TICKET_CATEGORY_TYPE_ENUM.I;
        break;
      case TICKET_CATEGORY_TYPE_ENUM.M:
        icon = TICKET_CATEGORY_TYPE_ENUM.M;
        break;
      case TICKET_CATEGORY_TYPE_ENUM.P:
        icon = TICKET_CATEGORY_TYPE_ENUM.P;
        break;
      default:
        icon = TICKET_CATEGORY_TYPE_ENUM.C;
        break;
    }

    return icon;
  };

  const isNASeat = (seat: SeatType) => {
    return (
      aboardSeats.filter((aSeat) =>
        aSeat.customers.find((customer) => customer.seat === seat._id)
      ).length > 0
    );
  };

  const generateTufesaStateWithTicket = (isReturn: boolean = false) => {
    const ticket: TicketType = {
      from: "",
      to: "",
      date: "",
      schedule: 0,
      folio: 0,
      customers: [],
    };

    if (!isReturn) {
      ticket.from = origin.idField;
      ticket.to = destination.idField;
      ticket.date = departureDate.replaceAll("-", "");
      ticket.schedule = departureSchedule._id;
      ticket.folio = generateRandomIntegerInRange(1000, 9999);
      ticket.customers = aboardSeats.map((aSeat) => aSeat.customers).flat();
      tufesaState.departureTicket = ticket;
    } else {
      ticket.from = destination.idField;
      ticket.to = origin.idField;
      ticket.date = returnDate.replaceAll("-", "");
      ticket.schedule = returnSchedule._id;
      ticket.folio = generateRandomIntegerInRange(1000, 9999);
      ticket.customers = aboardSeats.map((aSeat) => aSeat.customers).flat();
      tufesaState.returnTicket = ticket;
    }

    return tufesaState;
  };

  const onSeatClick = async (seat: SeatType) => {
    const category = departureSchedule._category.find(
      (c) => c._id === currentTicket.type
    );
    if (category._remain === 0) {
      await messageModal({
        message: `La categoria ${currentTicket.title} no cuenta con asientos disponibles`,
      });
      return;
    }

    const { message, data } = await seatAssigmentModal({ seat });
    if (message === "success") {
      const temLocalTickets = localTickets
        .map((lTicket) => {
          if (data.category === lTicket.type) {
            lTicket.quantity = lTicket.quantity - 1;
          }

          return lTicket;
        })
        .filter((lTicket) => lTicket.quantity > 0);

      let newDepartureAboardSeats: AboardSeatType[] = aboardSeats;
      const categoryAboardAlready = aboardSeats.find(
        (aSeat) => aSeat.category === seat._category
      );

      // if category is not in.
      if (!categoryAboardAlready) {
        newDepartureAboardSeats.push({
          category: seat._category,
          customers: [],
        });
      }

      // Check customers.
      newDepartureAboardSeats = newDepartureAboardSeats.map((das) => {
        if (das.category === data.category) {
          das.customers.push({
            name: `${data.name} ${data.lastname}`,
            lastname: data.lastname,
            category: data.category,
            seat: data.seat,
          });
        }

        return das;
      });

      setAboardTickets(newDepartureAboardSeats);
      setlocalTickets(temLocalTickets);

      // If we have more seats (return) then
      if (
        temLocalTickets.length === 0 &&
        tufesaState.choosingDepartureSeats &&
        !tufesaState.choosingReturnSeats &&
        !!returnDate
      ) {
        setCurrentTicket(null);
        setSeatRows([]);
        setAboardTickets([]);
        setlocalTickets(deepCopy(tickets));
        setAppState({
          ...context,
          tufesaState: {
            ...generateTufesaStateWithTicket(),
            choosingDepartureSeats: false,
            choosingReturnSeats: !!returnDate,
          },
        });
      } else if (temLocalTickets.length === 0) {
        setAppState({
          ...context,
          tufesaState: {
            ...generateTufesaStateWithTicket(!!returnDate),
            choosingDepartureSeats: false,
            choosingReturnSeats: false,
            stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.PAGO],
            pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.RESUMEN],
          },
        });
      }
    }
  };

  const hasCurrentLocaltickets =
    localTickets?.filter((ticket) => ticket.quantity > 0)?.length > 0;

  const onBackHandler = () => {
    setAppState({
      ...context,
      tufesaState: {
        ...tufesaState,
        stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.ASIENTOS],
        pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.FECHA],
        choosingDepartureSeats: false,
        choosingReturnSeats: false,
        departureSchedule: null,
        returnSchedule: null,
      },
    });
  };

  const onEditTicketsHandler = async () => {
    const ticketCategoryModalResult = await ticketCategoryModal();

    if (ticketCategoryModalResult?.message === "success") {
      setAppState({
        ...context,
        tufesaState: {
          ...tufesaState,
          stepView: STEP_VIEW_LIST[STEP_VIEW_ITEMS.ASIENTOS],
          pageView: PAGE_VIEW_LIST[PAGE_VIEW_ITEMS.FECHA],
          choosingDepartureSeats: false,
          choosingReturnSeats: false,
          departureSchedule: null,
          returnSchedule: null,
          tickets: ticketCategoryModalResult?.data,
        },
      });
    }
  };

  return (
    <ScrollContainer
      className="flex flex-col w-5/6 items-center mx-auto"
      style={{ maxHeight: "calc(100vh - 285px)" }}
    >
      {hasCurrentLocaltickets && (
        <div className="mt-2">
          Asiento de {choosingDepartureSeats ? "ida" : "vuelta"}:
          <span className="text-lg font-bold ml-2">{currentTicket?.title}</span>
        </div>
      )}

      {/*  Seat type */}
      <div className="flex flex-col w-full text-center">
        {aboardSeats.length === 0 && (
          <span>
            Pasajeros por abordar
            <span
              onClick={onEditTicketsHandler}
              className="text-tufesa-main ml-2 cursor-pointer"
            >
              Editar
            </span>
          </span>
        )}
        <div className="flex flex-col mt-1">
          <div className=" flex flex-row justify-center">
            {localTickets
              .filter((ticket) => ticket.quantity > 0)
              .map((ticket, index) => {
                return (
                  <span
                    key={ticket.type}
                    className="flex flex-row w-1/6 items-center justify-center"
                  >
                    <div className="w-full">
                      <img
                        className={`border border-gray-500 rounded-full w-8 h-8 cursor-pointer mx-auto ${
                          ticket.type === currentTicket?.type
                            ? "animate-bounce border-tufesa-main"
                            : ""
                        }`}
                        src={TICKET_TYPE_ICON_ENUM[getIcon(ticket.type)]}
                        alt={ticket.type}
                      />
                      <div className="text-center">
                        {ticket.title}:
                        <span className="text-tufesa-main ml-1">
                          {ticket.quantity}
                        </span>
                      </div>
                    </div>
                  </span>
                );
              })}
          </div>
          {/* <div className="flex mt-4">
            <div className="flex flex-col mx-auto">
              <img
                className="border border-gray-500 text-tufesa-main rounded-full w-8 h-8 cursor-pointer mx-auto"
                src="https://ventatufesa.azurewebsites.net/v3/assets/iconos/iconos%20web-16.png"
              />
              <div className="text-center">Modificar</div>
            </div>
          </div> */}
        </div>
      </div>

      {/*  Aboard Seats */}
      {aboardSeats.length > 0 && (
        <div className="flex flex-col w-full text-center my-1">
          {!hasCurrentLocaltickets && (
            <span>
              Pasajeros a bordo
              <span
                onClick={onEditTicketsHandler}
                className="text-tufesa-main ml-2 cursor-pointer"
              >
                Editar
              </span>
            </span>
          )}
          <div className=" flex flex-row justify-center mt-1">
            {aboardSeats.map((aSeat) => {
              return aSeat.customers.map((customer) => {
                return (
                  <span
                    key={customer.seat}
                    className="flex flex-row w-1/6 items-center justify-center"
                  >
                    <div className="w-full">
                      <img
                        className="border border-gray-500 rounded-full w-8 h-8 cursor-pointer mx-auto"
                        src={TICKET_TYPE_ICON_ENUM[getIcon(customer.category)]}
                        alt={customer.category}
                      />
                      <div className="text-center">{customer.name}</div>
                      <div className="text-center">
                        Asiento: {customer.seat}
                      </div>
                    </div>
                  </span>
                );
              });
            })}
          </div>
        </div>
      )}

      {/*  Seat color definition */}
      <div className="flex flex-row w-full h-4 mt-2">
        <div className="flex w-1/3">
          <div className="flex mx-auto">
            <span className="rounded-full bg-gray-400 h-4 w-4 block" />
            <span className="ml-2">Ocupado</span>
          </div>
        </div>
        <div className="flex w-1/3">
          <div className="flex mx-auto">
            <span className="rounded-full bg-tufesa-green h-4 w-4 block" />
            <span className="ml-2">Libre</span>
          </div>
        </div>
        <div className="flex w-1/3">
          <div className="flex mx-auto">
            <span className="rounded-full bg-tufesa-main h-4 w-4 block" />
            <span className="ml-2">Seleccion</span>
          </div>
        </div>
      </div>

      {/* Seat map */}
      <div className="flex flex-row w-5/6">
        <img
          className="w-1/6 h-72"
          src="https://ventatufesa.azurewebsites.net/v3/assets/images/frentebus2.png"
        />
        <div
          className="w-4/6 flex items-center justify-center border-t-2 border-b-2"
          style={{
            marginTop: "1.625rem",
            marginBottom: "-1.625rem",
            height: "14.3rem",
          }}
        >
          <div className="">
            {seatRows.map((srow, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-row w-full ${
                    index + 1 === 2 ? "mb-6" : "mb-2"
                  }`}
                >
                  {srow._seat.map((seat) => {
                    return (
                      <div
                        key={seat._id}
                        className="p-1"
                        onClick={() =>
                          seat._available && !isNASeat(seat)
                            ? onSeatClick({
                                ...seat,
                                _category: currentTicket.type,
                              })
                            : null
                        }
                      >
                        <span
                          className={`flex items-center justify-center ${
                            seat._available
                              ? !isNASeat(seat)
                                ? "cursor-pointer bg-tufesa-green border border-tufesa-green"
                                : "bg-tufesa-main border border-tufesa-main"
                              : "bg-gray-400 border border-gray-400"
                          } text-white rounded-full w-8 h-8`}
                        >
                          {seat._id}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <img
          className="w-1/6 h-72"
          src="https://ventatufesa.azurewebsites.net/v3/assets/images/colabus2.png"
        />
      </div>

      <div className="flex flex-row w-5/6">
        <button
          className="border text-tufesa-main border-tufesa-main hover:text-white hover:bg-tufesa-main rounded-full py-2 px-12 mx-auto"
          onClick={onBackHandler}
        >
          Regresar
        </button>
      </div>
    </ScrollContainer>
  );
};

export default Seats;
