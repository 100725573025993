import * as React from 'react';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import Ticket from 'components/Tufesa/Ticket';
import AppContext from 'AppContext';
import { TicketCategoryType } from 'types';
import { deepCopy } from 'libs';

export interface Props {
}

const TicketCategoryModal: React.FC<Props & ModalProps> = ({ onDismiss, onReject, onSubmit }) => {
  const context = React.useContext(AppContext);
  const { tufesaState } = context;
  const { tickets } = tufesaState;
  const [localTickets, setLocalTickets] = React.useState<TicketCategoryType[]>(deepCopy(tickets));
  const [totalTickets, setTotalTickets] = React.useState<number>(0);
  const [valid, setValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    const tTickets = localTickets.reduce((acc, t) => acc + t.quantity, 0);
    setTotalTickets(tTickets);
    setValid(totalTickets > 0 && totalTickets <= 10);

  }, [localTickets, totalTickets]);

  const onAddHandler = (ticket: TicketCategoryType) => {
    const newLocalTickets = localTickets.map(tckt => {
      if (ticket.type === tckt.type && tckt.quantity < 10) {
        tckt.quantity++;
      }

      return tckt;
    });

    setLocalTickets(newLocalTickets);
  };
  
  const onRemoveHandler = (ticket: TicketCategoryType) => {
    const newLocalTickets = localTickets.map(tckt => {
      if (ticket.type === tckt.type && tckt.quantity > 0) {
        tckt.quantity--;
      }

      return tckt;
    });

    setLocalTickets(newLocalTickets);
  };

  const onSubmitHanlder = () => {
    onSubmit({ message: 'success', data: localTickets });
  };

  return (
  <Modal
    onDismiss={onDismiss}
  >
    <ModalHeader>Pasajero(s)</ModalHeader>
    <ModalBody>
      <div className="flex flex-col w-full">
        <div className="mb-4">
          <p className="text-sm text-gray-500 whitespace-pre-line pb-2 text-center">
            Indica el tipo y la cantidad de pasajeros.<br />
            Recuerda que solo puedes comprar 10 boletos a la vez.
          </p>
        </div>
        <div>
          {localTickets.map(ticket => {
            return (
              <Ticket key={ticket.type} ticket={ticket} addHandler={onAddHandler} removeHandler={onRemoveHandler} />
            )
          })}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${valid ? 'bg-tufesa-dark hover:bg-tufesa-main' : 'bg-gray-300'} text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
        onClick={valid ? () => onSubmitHanlder() : null}
      >
        Continuar
      </button>
      <button
        type="button"
        className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={onReject}
      >
        Cancelar
      </button>
    </ModalFooter>
  </Modal>
)};

export default TicketCategoryModal;
