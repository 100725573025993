import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { serviceNameResolver } from 'libs';
import AppContext from 'AppContext';
import { getServiceByDevice } from 'services/ServiceService';
import { PERMISSIONS, ServiceMenuItemType, ServiceType } from 'types';
import { SERVICE_TYPE, SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import gifCard from '../../assets/img/icons/giftcard.png';
import other from '../../assets/img/icons/other.png';
import home from '../../assets/img/icons/home.png';
import insurance from '../../assets/img/icons/insurance.png';
import phone from '../../assets/img/icons/phone.png';
import ticket from '../../assets/img/icons/ticket.png';

const menuIcons = (icon_name: string) => {
  const definitions: any = {
    [SERVICE_TYPE.CELLPHONE_MINUTES]: phone,
    [SERVICE_TYPE.SERVICES_PAYMENTS]: home,
    [SERVICE_TYPE.TICKET]: ticket,
    [SERVICE_TYPE.INSURANCE]: insurance,
    [SERVICE_TYPE.ENTERTAINMENT]: gifCard,
    [SERVICE_TYPE.OTHER]: other,
  };

  return definitions[icon_name];
}

interface MenuSProps { }

const MenuS: React.FC<MenuSProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const [serviceMenuItems, setServiceMenuItems] = React.useState<
    ServiceMenuItemType[]
  >([]);

  const fetchServices = React.useCallback(async () => {
    const svcs = await getServiceByDevice() as ServiceType[];
    const tempServiceMenuItems: ServiceMenuItemType[] = [];
    const pos = mainState.session?.permissions?.includes(PERMISSIONS.POS);

    setAppState({
      ...context,
      mainState: {
        ...mainState,
        services: svcs
      },
    });

    svcs.forEach((service) => {
      if(!pos && service.name === 'Token' && service.type === SERVICE_TYPE.OTHER){
        return;
      }

      if (!tempServiceMenuItems.some((item) => item.type === service.type)) {
        tempServiceMenuItems.push({
          type: service.type,
          name: service.type,
          icon: '',
        })
      }
    });

    setServiceMenuItems(tempServiceMenuItems);
  }, []);

  React.useEffect(() => {
    fetchServices();
  }, [fetchServices])

  const onServiceClickHandler = (item: ServiceMenuItemType) => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.SERVICE,
        selectedServiceType: item.type,
      }
    });
  }

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-4xl md:text-6xl font-myriad-bold text-center">
        Selecciona servicio a pagar
      </h1>
      <ScrollContainer
        className="flex flex-col mt-12"
        style={{ height: 'calc(100vh - 230px)' }}
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-12 pb-2">
          {serviceMenuItems.map((item) => {
            return (
              <div
                key={item.type}
                onClick={() => onServiceClickHandler(item)}
                className="flex flex-col"
              >
                <div
                  className="relative flex items-center justify-center h-52 rounded-lg bg-kiosk-red p-4 border border-kiosk red text-white font-bold drop-shadow-xl"
                >
                  <img src={menuIcons(item.type)} alt="service logo" className="h-24" />
                  <span className="absolute bottom-0 text-xl uppercase mt-2">{serviceNameResolver(item.type)}</span>
                </div>
                {/* <span className="flex items-center justify-center text-center text-xl mt-2">
                  {serviceNameResolver(item.type)}
                </span> */}
              </div>
            )
          })}
        </div>
      </ScrollContainer>
    </div>
  )
}

export default MenuS
