import { apolloClient } from "libs/apolloClient";
import { gql } from "@apollo/client";
import ErrorHandler from "libs/ApolloErrorHandler";

const cutReportService = () => {
  return apolloClient()
    .mutate({
      mutation: gql`
        mutation cutReport {
          cutReport {
            message
            ticketHtml
          }
        }
      `,
    })
    .then(res => ErrorHandler(res))
    .then(({ data }): any => {
      const cutReport = data.cutReport;

      return cutReport;
    });
};

export { cutReportService };
