import React from "react";
import Keyb from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const layout = {
  'default': [
    '1 2 3 4 5 6 7 8 9 0',
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m .',
    '.com @ {bksp}'
  ],
  'numbers': ["1 2 3", "4 5 6", "7 8 9", " 0 ", "{bksp}"],
}

export enum KeyboardlVariant {
  default = 'default',
  numbers = 'numbers',
}

export type KeyboardVariantTokens = keyof typeof KeyboardlVariant;

interface KeyboardProps {
  onChange: (input: string) => void;
  keyboardRef: React.MutableRefObject<typeof Keyboard>;
  variant?: KeyboardVariantTokens
}

const Keyboard: React.FC<KeyboardProps> = ({ onChange, keyboardRef, variant }) => {
  const [layoutName, setLayoutName] = React.useState<string>(variant || KeyboardlVariant.default);

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  };

  return (
    <Keyb
      keyboardRef={(r: any) => (keyboardRef.current = r)}
      layout={layout}
      layoutName={layoutName}
      display={{
        '{bksp}': 'Borrar',
      }}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onRender={() => console.log("Rendered")}
    />
  );
};

export default Keyboard;