export default class PinPadEvents {
  sendMessage = (data: any) => {
    window.postMessage(data, document.location.toString());
  };
  handleMessage = (callback: (data: any) => void) => {
    window.addEventListener(
      "message",
      ({ data }: any) => callback(data),
      false
    );
  };
}
