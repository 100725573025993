import * as React from 'react';
import { ModalProps } from 'components/UI/Modal/ModalProvider';
import { Modal } from 'components/UI';
import ModalBody from 'components/UI/Modal/ModalBody';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import Keyboard from 'components/UI/Keyboard';


export interface ConfirmPhoneNumberModalProps {
  phoneNumber: string
}

const ConfirmPhoneNumberModal: React.FC<ConfirmPhoneNumberModalProps & ModalProps> = ({ onSubmit, onDismiss, phoneNumber }) => {
  const keyboardRef = React.useRef<any>();
  const [confirmNumber, setConfirmNumber] = React.useState<string>('');
  const [valid, setValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValid(confirmNumber === phoneNumber);
  }, [confirmNumber])

  const onChangeHandler = (e: React.SyntheticEvent) => {
    const { value } = e.currentTarget as HTMLInputElement;

    setConfirmNumber(value);
    keyboardRef.current.setInput(value);
  }

  const onKChangeHandler = (value: string): void => {
    setConfirmNumber(value);
  }

  return (
    <Modal variant="md">
      <ModalHeader showCloseButon={false}>
        Mensaje
      </ModalHeader>
      <ModalBody>
        <div className="flex w-full">
          <div className="flex flex-col w-full text-center items-center justify-center">
            <span className="text-xl text-gray-600 font-bold mb-8">Por favor confirma el numero telefónico</span>

            <input 
              value={confirmNumber}
              className="w-5/6 mx-auto p-4 border border-gray-300 mb-8 focus:outline-none focus:ring-2 focus:ring-kiosk-red"  
              onChange={onChangeHandler}
            />

            <Keyboard keyboardRef={keyboardRef} variant="numbers" onChange={onKChangeHandler} />

            <div className="flex flex-row space-x-4 mt-4 justify-center">
              <button
                className="flex px-6 py-4 border rounded border-gray-400 bg-gray-600 items-center justify-center text-white uppercase"
                onClick={onDismiss}
              >
                Cancelar
              </button>
              <button
                className={`flex px-6 py-4 border rounded ${valid ? 'bg-kiosk-red border-kiosk-red hover:bg-red-500' : 'bg-gray-300 border-gray-400'}items-center justify-center text-white uppercase`}
                onClick={() => valid ? onSubmit({ data: 'success' }) : null}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ConfirmPhoneNumberModal;
