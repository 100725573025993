import * as React from "react";

import logo from "assets/img/logos/logo-pagocheck.png";
import helpIcon from "assets/img/helpIcon.png";
import AppContext, { AppContextType } from "AppContext";
import { SYSTEMATIC_ROUTER_ENUM } from "../../../constants";
import { defaultTufesaState } from "AppProvider";
import { deepCopy } from "libs";
import { PERMISSIONS, ROLES } from "types";
import { logoutService } from "services/AuthService";
import { useModal } from "../Modal/ModalProvider";
import MessageModal from "../Message/MessageModal";

interface NavBarProps {
  landingLayout?: boolean;
  onBackHandler: (context: AppContextType) => void;
}

export const NavBar: React.FC<NavBarProps> = ({
  landingLayout,
  onBackHandler,
}) => {
  const context = React.useContext(AppContext);
  const messageModal = useModal(MessageModal);
  const { mainState, setAppState } = context;
  const { account, session } = mainState;
  const [open, setOpen] = React.useState<boolean>(false);
  const isPos = session?.permissions?.includes(PERMISSIONS.POS);

  const onLogoClick = () => window.location.reload();

  const onLogoutClick = () => {
    logoutService().then(() => {
      window.location.reload();
    }).catch(({ message }) =>  messageModal({ message }));
  };

  const onMenuItemClick = (component: SYSTEMATIC_ROUTER_ENUM) => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: component,
      },
    });
    setOpen(!open);
  };

  const onLockScreen = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        locked: true
      },
    });
  }

  const isAdminLogable = session?.roles?.some((rol) =>
    [ROLES.ADMIN, ROLES.SUPERVISOR].includes(rol)
  );

  return (
    <nav>
      <header className="bg-gray-100 border-b">
        <div className="flex py-2 px-6">
          <div
            className={`${
              landingLayout ? "w-full" : "w-1/3"
            } flex items-center`}
          >
            {landingLayout && (
              <img onClick={onLogoClick} className="w-52 sm:w-32 mx-auto" src={logo} />
            )}
            {!landingLayout && (
              <div className="flex justify-center items-center space-x-2">
              <span
                onClick={() => onBackHandler(context)}
                className="flex justify-center items-center bg-kiosk-red text-gray-300 h-12 sm:h-16 w-12 sm:w-16 rounded-full"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 26.676 26.676"
                  xmlSpace="preserve"
                  style={{ width: "65%" }}
                >
                  <g>
                    <path
                      fill="currentColor"
                      d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
                    c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
                    C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
                    c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
                    C26.18,21.891,26.141,21.891,26.105,21.891z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </span>
              </div>
            )}
          </div>

          {!landingLayout && (
            <div className="w-1/3 flex items-center justify-center">
              <div className="flex flex-col text-center">
                <img src={logo} className="w-52 mx-auto" />
              </div>
            </div>
          )}

          {!landingLayout && (
            <div className="w-1/3 flex items-center">
              {account && (
                <div className="w-2/3 flex justify-center">
                  <div className="flex items-center">
                    <div className="flex flex-col w-20 text-center">
                      <span className="text-2xl">Saldo</span>
                      <span className="text-xl">{`$${account.token.points}`}</span>
                    </div>
                  </div>
                </div>
              )}

              {isPos ? (
                <div className="w-1/3 flex justify-end ml-auto pr-2">
                  <div className="flex flex-col">
                    <span
                      className="flex justify-center items-center h-12 sm:h-16 w-12 sm:w-16 rounded-full bg-kiosk-red text-gray-300  cursor-pointer"
                      onClick={() => setOpen(!open)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-1/3 flex justify-end ml-auto pr-2">
                  <div className="flex flex-col">
                    <img src={helpIcon} className=" h-8 mx-auto" />
                    <span>AYUDA</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </header>

      {open && isPos && (
        <div
          className="w-full h-full bg-gray-500 absolute top-0 opacity-70 z-10"
          onClick={() => setOpen(!open)}
        />
      )}

      {isPos && (
        <div
          className={`transform top-0 right-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
            !open ? "translate-x-full" : "translate-x-0"
          }`}
        >
          <ul className="w-full h-full bg-white border border-gray-400">
            {isAdminLogable && (
              <li
                className="py-4 px-4 border-b border-gray-200 cursor-pointer hover:text-white hover:bg-kiosk-red"
                onClick={() => (window.location.href = process.env.ADMIN_URL)}
              >
                Admin
              </li>
            )}
            <li
              className="py-4 px-4 border-b border-gray-200 cursor-pointer hover:text-white hover:bg-kiosk-red"
              onClick={() => onMenuItemClick(SYSTEMATIC_ROUTER_ENUM.CUT)}
            >
              Corte de Caja
            </li>
            <li
              className="py-4 px-4 border-b border-gray-200 cursor-pointer hover:text-white hover:bg-kiosk-red"
              onClick={() => onMenuItemClick(SYSTEMATIC_ROUTER_ENUM.TOKEN)}
            >
              Generación de Token
            </li>
            <li
              className="py-4 px-4 border-b border-gray-200 cursor-pointer hover:text-white hover:bg-kiosk-red"
              onClick={onLockScreen}
            >
              Bloquear Sesión
            </li>
            <li
              className="py-4 px-4 border-b border-gray-200 cursor-pointer hover:text-white hover:bg-kiosk-red"
              onClick={onLogoutClick}
            >
              Cerrar Sesión
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
