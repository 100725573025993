import cron from 'cron';

export default class Cron {
  job: any;

  constructor(timeZone: string, callback: () => void) {
    this.job = new cron.CronJob('0 0 * * *',
    () => callback(),
    null, true, timeZone);
  }

  start (){
    this.job.start();
  }
  
  stop (){
    this.job.stop();
  }
}