import * as React from 'react';
import { TicketCategoryType } from 'types';

export interface TicketProps {
  ticket: TicketCategoryType,
  addHandler: (ticket: TicketCategoryType) => void,
  removeHandler: (ticket: TicketCategoryType) => void,
}

const Ticket: React.FC<TicketProps> = ({ ticket, addHandler, removeHandler }) => {

  return (
    <div className="flex py-4">
      <div className="w-2/6 text-left">
        <div className="text-2xl font-bold text-gray-700">{ticket.title}</div>
        <div className="text-gray-500">{ticket.details}</div>
      </div>
      <div className="flex items-center justify-center w-2/6 text-2xl text-center text-gray-700">
        <span>{ticket.quantity}</span>
      </div>
      <div className="flex justify-end w-2/6">
        <button
          onClick={() => removeHandler(ticket)}
          className="mr-1 w-1/3 h-full p-2 text-lg border border-tufesa-dark text-tufesa-dark hover:border-tufesa-main hover:text-tufesa-main">
          -
        </button>
        <button
          onClick={() => addHandler(ticket)}
          className="ml-1 w-1/3 h-full p-2 text-lg bg-tufesa-dark text-white hover:border-tufesa-main hover:bg-tufesa-main"
        >
          +
        </button>
      </div>
    </div>
  );
}

export default Ticket;