import React from 'react';
import AppContext from 'AppContext';
import touchIcon from 'assets/img/touchIcon.png';
import { SYSTEMATIC_ROUTER_ENUM } from '../../constants';
import { PERMISSIONS } from 'types';

interface WelcomeProps {

}

const Welcome: React.FC<WelcomeProps> = () => {
  const context = React.useContext(AppContext);
  const { mainState, setAppState } = context;
  const { session } = mainState;

  React.useEffect(() => {
    const isPos = session?.permissions?.includes(PERMISSIONS.POS);

    if (isPos) {
      setAppState({
        ...context,
        mainState: {
          ...mainState,
          currentComponent: SYSTEMATIC_ROUTER_ENUM.MENU_SERVICES
        }
      });
    }
  }, [session]);

  const onClickHandler = () => {
    setAppState({
      ...context,
      mainState: {
        ...mainState,
        currentComponent: SYSTEMATIC_ROUTER_ENUM.PAYMENT_TYPE
      }
    });
  }

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col text-center">
        <h1 className="text-8xl font-myriad-bold mb-4">Bienvenido</h1>
        <h5 className="text-4xl mb-12">Presiona para comenzar</h5>
        <img onClick={onClickHandler} src={touchIcon} alt="Dedo" className="w-40 h-36 mx-auto" />
      </div>
    </div>
  );
}

export default Welcome;