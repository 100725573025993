import * as React from 'react';
import StepController from './Steps/StepController';

export interface TufesaProps {

}

const Tufesa: React.FC<TufesaProps> = () => {
  return (
    <StepController />
  );
}


export default Tufesa;