import * as React from "react";
import NumberFormat from "react-number-format";

import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalFooter from "components/UI/Modal/ModalFooter";
import ModalHeader from "components/UI/Modal/ModalHeader";
import AppContext from "AppContext";
import Keyboard, { KeyboardlVariant } from "components/UI/Keyboard";
import { validateEmail } from "libs";
import { PERMISSIONS } from "types";

export interface ContactModalProps {}

const ContactModal: React.FC<ContactModalProps & ModalProps> = ({
  onDismiss,
  onReject,
  onSubmit,
}) => {
  const keyboardRef = React.useRef<any>();
  const context = React.useContext(AppContext);
  const { mainState, tufesaState } = context;
  const { email, phone, marketing } = tufesaState;
  const [state, setState] = React.useState({
    phone: phone || "",
    email: email || "",
    marketing,
  });
  const [privacyCheck, setPrivacyCheck] = React.useState<boolean>(false);
  const [showPrivacyText, setShowPrivacyText] = React.useState<boolean>(false);
  const [focusEmail, setFocusEmail] = React.useState<boolean>(false);
  const [focusPhone, setFocusPhone] = React.useState<boolean>(false);
  const [canContinue, setCanContinue] = React.useState<boolean>(false);
  const [isPos, setIsPos] = React.useState<boolean>(false);

  // const onMarketingHandler = () => {
  //   setState({
  //     ...state,
  //     marketing: !marketing
  //   })
  // }

  React.useEffect(() => {
    const bothDirty = state.phone && state.email;
    let can = false;
    if (bothDirty) {
      can = state.phone.length === 10 && validateEmail(state.email);
    } else {
      can =
        (state.phone || state.email) &&
        ((state.phone && state.phone.length === 10) ||
          (state.email && validateEmail(state.email)));
    }

    if(mainState.session?.permissions?.includes(PERMISSIONS.POS)){
      setIsPos(true);
    }

    setCanContinue(can);
  }, [state, mainState.session]);

  const onChangeHandler = (el: React.SyntheticEvent) => {
    const input = el.currentTarget as HTMLInputElement;
    const { name, value } = input;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onChangeInput = (input: string): void => {
    if (focusPhone) {
      input = input.length >= 10 ? input.slice(0, 10) : input;

      setState({
        ...state,
        phone: input,
      });
    } else if (focusEmail) {
      setState({
        ...state,
        email: input,
      });
    }

    keyboardRef?.current?.setInput(input);
  };

  const getKeyboardVariant = () => {
    if (focusPhone) {
      return KeyboardlVariant.numbers;
    } else if (focusEmail) {
      return KeyboardlVariant.default;
    } else {
      return KeyboardlVariant.default;
    }
  };

  return (
    <Modal variant="md" onDismiss={onDismiss}>
      <ModalHeader>Datos de contacto</ModalHeader>
      <ModalBody>
        {!showPrivacyText ? (
          <div className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              {/* <input
              name="phone"
              onFocus={() => {
                keyboardRef?.current?.setInput(state.phone);
                setFocusEmail(false);
                setFocusPhone(true);
              }}
              value={state.phone}
              className={`p-4 border rounded mb-2 border-gray-400 focus:outline-none focus:border-tufesa-main ${focusPhone && 'border-tufesa-main'}`}
              placeholder="Teléfono"
              onChange={onChangeHandler}
            /> */}

              <div
                onClick={() => {
                  keyboardRef?.current?.setInput(state.phone);
                  setFocusEmail(false);
                  setFocusPhone(true);
                }}
                className={`border rounded mb-2 border-gray-400 focus:outline-none focus:border-tufesa-main ${
                  focusPhone && "border-tufesa-main"
                }`}
              >
                <NumberFormat
                  disabled={!isPos}
                  format="+52 (###) ###-####"
                  mask="_"
                  value={state.phone}
                  allowEmptyFormatting={true}
                  className="w-full p-4 bg-transparent text-base focus:outline-none"
                  onChange={({ value }: any) => !isPos ? onChangeInput(value) : null}
                  onValueChange={({ value }: any) => isPos ? onChangeInput(value) : null}
                />
              </div>

              <input
                name="email"
                onFocus={() => {
                  keyboardRef?.current?.setInput(state.email);
                  setFocusPhone(false);
                  setFocusEmail(true);
                }}
                value={state.email}
                className={`p-4 border rounded mb-2 border-gray-400 focus:outline-none focus:border-tufesa-main ${
                  focusEmail && "border-tufesa-main"
                }`}
                placeholder="Correo (optional)"
                onChange={onChangeHandler}
              />
            </div>
            {(focusPhone || focusEmail) && (
              <Keyboard
                key={focusPhone ? "p" : "n"}
                variant={getKeyboardVariant()}
                keyboardRef={keyboardRef}
                onChange={onChangeInput}
              />
            )}
            <div className="mt-8">
              <div className="max-w-xs mx-auto bg-white">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="privacy"
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-tufesa-main checked:border-tufesa-dark focus:outline-none"
                    defaultChecked={privacyCheck}
                    onClick={() => setPrivacyCheck(!privacyCheck)}
                  />
                  <span className="text-gray-500 font-medium">
                    Acepto los
                    <a
                      className="text-tufesa-main cursor-pointer pl-1"
                      onClick={() => setShowPrivacyText(true)}
                    >
                      Términos y Condiciones
                    </a>
                  </span>
                </label>
                {/* <label className="flex items-center space-x-3 mt-2">
                <input 
                  type="checkbox" 
                  name="marketing"                   
                  defaultChecked={marketing}
                  className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-tufesa-main checked:border-tufesa-dark focus:outline-none" 
                  onClick={onMarketingHandler}
                />
                <span className="text-gray-500 font-medium">Quiero recibir información y publicidad</span>
              </label> */}
              </div>
            </div>
          </div>
        ) : (
          <iframe
            className="w-full h-96"
            src="https://www.tufesa.com.mx/informacion/avisoPrivacidad"
          />
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
            privacyCheck && canContinue
              ? "bg-tufesa-dark hover:bg-tufesa-main"
              : "bg-gray-300"
          } text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={() =>
            canContinue ? onSubmit({ message: "success", data: state }) : null
          }
        >
          Continuar
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-tufesa-main shadow-sm px-4 py-2 bg-white text-base font-medium text-tufesa-main focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          style={{ outline: "none!important" }}
          onClick={
            !showPrivacyText ? onReject : () => setShowPrivacyText(false)
          }
        >
          {!showPrivacyText ? "Cancelar" : "Atrás"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactModal;
