import React, { Ref } from 'react'
import AppContext from 'AppContext'

export enum NumberPadVariant {
  reference = 'reference',
  amount = 'amount',
}

export type NumberPadVariantTokens = keyof typeof NumberPadVariant;


interface NumberPadProps {
  clearButtonRef?: Ref<HTMLButtonElement>,
  onClear?: () => void,
  onContinue: (value: string) => void,
  onChange?: (value: string) => void,
  mode: NumberPadVariantTokens,
  title?: string | JSX.Element,
  value: string,
  placeholder?: string,
  enableContinueRule?: boolean
}

const NumberPad: React.FC<NumberPadProps> = ({
  title,
  mode,
  enableContinueRule = true,
  onContinue,
  onChange,
  onClear,
  clearButtonRef,
  placeholder,
  value
}) => {
  const [numepadValue, setNumepadValue] = React.useState<string>(value)
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const isAmount = mode === NumberPadVariant.amount;

  if (isAmount) {
    keys.push('.');
  }

  const onPadClick = (value: string) => {
    let newValue = numepadValue;

    newValue = `${numepadValue}${value}`;

    if ((newValue.match(/\./g) || []).length > 1) {
      newValue = newValue.substring(0, newValue.length - 1);
    }

    setNumepadValue(newValue);
    onChange(newValue);
  }

  const onChangeHandler = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLFormElement;
    let { value } = target;

    if ((value.match(/\./g) || []).length > 1) {
      value = value.substring(0, value.length - 1);
    }

    setNumepadValue(value);
    onChange(value);
  }

  const onContinueHandler = () => {
    onContinue(numepadValue);
  }

  const onClearHandler = () => {
    setNumepadValue('');
    onClear && onClear();
  }
  
  const onBackSpaceHandler = () => {
    const newNumepadValue = numepadValue.length > 0 ? 
    numepadValue.substring(0, numepadValue.length - 1) : numepadValue;

    setNumepadValue(newNumepadValue);
    onChange(newNumepadValue);
  }

  const hasValue = numepadValue.length > 0;

  return (
    <div className="h-full w-full">
      <div className="flex flex-col">
        <div className="flex flex-col w-full ">

          <div className='my-4'>
            <label htmlFor="price" className="flex justify-center text-3xl font-bold text-gray-700 mb-4 text-center">
              {title}
            </label>
            <div className=" relative rounded-md shadow-sm">
              <input
                // readOnly
                id="price"
                type="text"
                name="value"
                value={numepadValue}
                placeholder={placeholder}
                onChange={onChangeHandler}
                className="py-4 px-4 text-4xl focus:outline-none focus:ring-red-500 focus:border-red-500 block w-full border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="grid grid-cols-3 gap-6">
            {keys.map((k, index) => {
              return (
                <div
                  key={`numberpad-${k}`}
                  onClick={() => onPadClick(k)}
                  className={`py-4 text-3xl border border-gray-300 hover:bg-kiosk-red hover:text-white rounded-lg text-center shadow-md ${!isAmount && (index === keys.length - 1) && 'col-span-3'} ${isAmount && (index === keys.length - 2) && 'col-span-2'}`}
                >
                  {k}
                </div>
              )
            })}
          </div>

          <div className="flex gap-1 mt-8 text-2xl font-bold">
            <button
              ref={clearButtonRef}
              disabled={!hasValue}
              onClick={onClearHandler}
              className={`w-1/3 rounded-lg border py-4 ${hasValue ? 'bg-gray-300 border-gray-400' : 'bg-gray-200'}`}
            >
              Limpiar
            </button>
            <button
              disabled={!hasValue}
              onClick={onBackSpaceHandler}
              className={`w-1/3 rounded-lg border py-4 ${hasValue ? 'bg-yellow-500 border-yellow-600 test-white' : 'bg-gray-200'}`}
            >
              Corregir
            </button>
            <button
              disabled={!hasValue}
              onClick={() => hasValue && enableContinueRule ? onContinueHandler() : null}
              className={`w-1/3 rounded-lg border-1 py-4 ${hasValue && enableContinueRule ? 'bg-kiosk-red text-white border-red-900' : 'bg-gray-200 border-gray-400'}`}
            >
              Continuar
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default NumberPad
